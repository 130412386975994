// 针对消息中心UAT新增样式表
.account-wrap.message-wrap {
    .ant-tabs-nav {
        font-weight: 500;
        color: #888;
        .ant-tabs-tab-active {
            font-weight: bold;
            color: #000;
        }
    }

    .ant-select-selection-selected-value {
        width: 100%;
        text-align: center;
    }
    .ant-select-selection--single .ant-select-selection__rendered,
    .message-button > .ant-btn {
        font-size: 12px;
    }
}
.message-option {
    &.small-option {
        width: 82px;
        box-shadow: none;
        border: 1px solid #ccc;
        border-radius: 5px;

        .ant-select-dropdown-menu {
            padding: 0;
        }

        .ant-select-dropdown-menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 5px;
            height: 32px;
            font-size: 12px;
            text-align: center;

            &.ant-select-dropdown-menu-item-selected {
                background: none;
            }
        }
    }
}

.message-selection {
    .ant-select-selection {
        border-radius: 5px;
        color: #222222;
        border-color: #e3e3e3;
    }
}

.message-container {
    .ant-tabs-bar {
        &.ant-tabs-top-bar {
            margin-bottom: 20px;
        }
    }

    .border-tabs {
        .ant-tabs-nav-wrap {
            justify-content: flex-start;
            .ant-tabs-tab {
                font-size: 14px;
            }
        }

        .ant-tabs-top-bar .ant-tabs-tab.ant-tabs-tab-active {
            font-size: 14px;
        }

        .ant-tabs-ink-bar {
            height: 3px;
        }
    }

    .message-button {
        &.announcement {
            .ant-select {
                width: 115px;
            }
        }
        .ant-select {
            width: 82px;
        }

        .ant-btn {
            padding: 0 8px;
            width: 142px;
            border-radius: 5px;
            color: #222222;
            border-color: #e3e3e3;
            &:hover {
                color: #29bbff;
            }
        }
    }
}

.markAllRead {
    &.ant-modal-confirm {
        .ant-modal-close {
            display: none;
        }

        .ant-modal-content {
            border-radius: 20px;

            .ant-modal-body {
                padding: 0;

                .ant-modal-confirm-title {
                    color: #ffffff;
                    font-size: 20px;
                    background-color: #00a6ff;
                    width: 100%;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-top-right-radius: 20px;
                    border-top-left-radius: 20px;
                }

                .ant-modal-confirm-content {
                    color: #222;
                    margin-top: 30px;
                    padding: 0px 59px;
                }

                .ant-modal-confirm-btns {
                    margin: 30px;
                    .ant-btn {
                        width: 160px;
                        height: 40px;
                        font-size: 16px;
                        color: #00a6ff;
                    }
                    .ant-btn-primary {
                        color: #ffffff;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .ant-btn,
    .ant-btn.ant-btn-primary {
        letter-spacing: -2px;
    }
}
