.form-item {
    .input-error-message {
        background-color: #fee5e5;
        border-radius: 8px;
        text-align: left;
        font: normal normal normal 12px/17px "Noto Sans Thai", Arial, san-serif;
        letter-spacing: 0px;
        color: #eb2121;
        opacity: 1;
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        white-space: pre-line;
    }
}
