.error-wrapper {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 20px #0000000d;
    border-radius: 20px;
    opacity: 1;
    margin-top: 60px;
    display: flex;
    .error-img {
        width: 540px;
        height: 480px;
        border-radius: 20px 0px 0px 20px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .error-right {
        width: 580px;
        height: 480px;
        padding: 50px 80px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        justify-content: center;
        .error-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 260px;
            .title {
                font: normal normal normal 18px/26px "Noto Sans Thai", Arial, san-serif;
                text-align: center;
                letter-spacing: 0px;
                color: #222222;
                opacity: 1;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0px;
                background-color: transparent;
            }
            .error-icon {
                width: 58px;
                height: 58px;
                margin: 30px 0;
                img {
                    width: 100%;
                }
            }
            //restrictAccess頁面的 block icon
            .error-block {
                margin: 30px 0;
            }
            .error-mes {
                text-align: center;
                font: normal normal normal 14px/24px "Noto Sans Thai", Arial, san-serif;
                letter-spacing: 0px;
                color: #666666;
                opacity: 1;
            }
            .error-btn {
                margin: 30px 0 20px;
                padding: 8px;
                width: 80%;
                border: 0;
                background: #00a6ff 0% 0% no-repeat padding-box;
                border-radius: 8px;
                opacity: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 16px;
                    height: 16px;
                }
                span {
                    text-align: center;
                    font-weight: normal;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    opacity: 1;
                    padding-left: 10px;
                }
            }
            .error-chat-btn, .error-home-btn {
                margin: 30px 0 20px;
                padding: 8px;
                width: 100%;
                border: 0;
                background: #00a6ff 0% 0% no-repeat padding-box;
                border: 1px solid #00a6ff;
                border-radius: 8px;
                opacity: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 16px;
                    height: 16px;
                }
                span {
                    text-align: center;
                    font: normal normal normal 14px/19px Microsoft YaHei;
                    letter-spacing: 0px;
                    color: #ffffff;
                    opacity: 1;
                    padding-left: 10px;
                }
            }
            .error-chat-btn {
                margin: 0 0 20px;
                background: #fff 0% 0% no-repeat padding-box;
                span {
                    color: #00a6ff;
                }
            }
            .error-information {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-self: center;
                .error-info-text {
                    span {
                        font: normal normal normal 14px/22px "Noto Sans Thai", Arial, san-serif;
                        letter-spacing: 0px;
                        color: #999999;
                        opacity: 1;
                    }
                    a {
                        text-align: center;
                        font: normal normal normal 14px/22px "Noto Sans Thai", Arial, san-serif;
                        letter-spacing: 0px;
                        color: #00a6ff;
                        opacity: 1;
                        padding-left: 18px;
                    }
                }
            }
            .maintain-title {
                text-align: left;
                width: 100%;
                margin-top: 12px;
                margin-bottom: 18px;
                color: #999999;
            }
            .maintain-wrapper {
                width: 248px;
                display: flex;
                column-gap: 34px;
                .maintain-item {
                    width: 60px;
                    height: 70px;
                    font-size: 30px;
                    color: #00a6ff;
                    padding: 16px 0px 13px;
                    text-align: center;
                    border-radius: 6px;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                }
                .maintain-text {
                    margin-top: 15px;
                    text-align: center;
                    color: #999999;
                }
            }
        }
    }
}

@hack: true;@import '/buildDir/data/less/antd-custom.less';