// radio style
.my-promotion-container {
    .my-promotion-filter-box {
        font-size: 14px;
        .ant-radio-button-wrapper {
            text-align: center;
            padding: 0px 12px;
            border: 1px solid #e3e3e3;
            border-left: 0;
            &:not(:first-child)::before {
                background-color: #e3e3e3;
            }
            &:first-child {
                border-left: 1px solid #e3e3e3;
            }
        }
        .ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper-checked:hover {
            background-color: #00a6ff;
            color: #fff !important;
            box-shadow: none !important;
            margin-bottom: 20px;
        }
    }
}

// list style
.my-promotion-list {
    min-height: 500px;
    margin-bottom: 32px;

    .filters-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .category-tabs {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .category-tab {
            height: 26px;
            font-size: 12px;
            padding: 4px 16px;
            color: #666;
            border: 1px solid #e3e3e3;
            border-radius: 20px;
            background-color: #ffffff;
            cursor: pointer;
        }

        .category-tab.active {
            background-color: #00a6ff1a; /* 透明度 10% */
            color: #00a6ff;
            border-color: #00a6ff;
        }
    }

    .big-empty-box {
        margin-top: 200px;
        p {
            font-size: 18px;
        }
    }

    .last-updated {
        font-size: 12px;
        color: #a1a1a1;
        margin-bottom: 20px;
    }

    .important-note {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 32px;

        .important-note-title {
            font-weight: 600;
            color: #222;
            margin-bottom: 8px;
        }

        .important-note-content {
            color: #666;
        }

        .highlight-bold {
            color: #222;
            font-weight: 600;
        }
    }
}

// card style
.my-promotion {
    &-card-container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    &-card {
        background: #fff;
        border-radius: 8px;
        padding: 16px;
        width: 362px;
        display: flex;
        flex-direction: column;

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            min-height: 44px;

            .card-title {
                font-size: 16px;
                font-weight: 600;
                color: #222;
                max-width: 210px;
                margin-right: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 22px;
                margin: 0;
            }

            .card-icons {
                display: flex;
                gap: 8px;
                margin-left: auto;

                .card-icon {
                    object-fit: contain;

                    &.large-icon {
                        width: 32px;
                        height: 32px;
                    }
                    
                    &.small-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .more-menu-container {
                height: 24px;
                position: relative;
                margin-left: 8px;
                cursor: pointer;
            }

            .more-menu-button img {
                width: 24px;
                height: 24px;
            }

            .more-menu {
                width: 160px;
                position: absolute;
                top: 100%;
                right: 0;
                background-color: #ffffff;
                box-shadow: 1px 4px 6px 0px rgba(0, 0, 0, 0.25);
                border-radius: 6px;
                z-index: 100;
                padding: 8px;
                border: 1px solid #e3e3e3;
            }

            .menu-item {
                padding: 8px;
                font-size: 14px;
                color: #666666;
                cursor: pointer;
                border-radius: 6px;
                transition: background-color 0.3s;
            }

            .menu-item:hover {
                background-color: #efeff4;
            }
        }

        .card-body {
            .card-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;

                .info-label {
                    font-size: 14px;
                    color: #666;
                }

                .info-value {
                    font-size: 18px;
                    font-weight: 600;
                    color: #222;
                }
            }

            .card-info-time {
                .info-label {
                    font-size: 12px;
                    color: #666;
                }

                .info-value {
                    font-size: 12px;
                    color: #666;
                    font-weight: normal; /* 移除加粗 */
                }
            }

            .promotion-completed-section {
                .card-info:last-child {
                    margin-bottom: 0px;
                    .status-container {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        &.status-complete {
                            color: #34c759;
                        }
                        &.status-cancelled,
                        &.status-expired {
                            color: #eb2121;
                        }
                    }
                }
            }
        }

        .card-footer {
            margin-top: auto;
            margin-bottom: auto;

            .promotion-not-started-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #e3e3e3; /* 頂部分隔線 */
                padding-top: 12px;

                .detail-button,
                .start-button {
                    flex: 1;
                    background-color: #fff;
                    border: none;
                    font-size: 16px;
                    cursor: pointer;
                }

                .detail-button {
                    color: #666666;
                }

                .start-button {
                    font-weight: 600;
                    color: #00a6ff;
                }

                .divider {
                    width: 1px;
                    height: 25px;
                    background-color: #e3e3e3; /* 分隔線 */
                    margin: 0 8px;
                }
            }

            .promotion-inProgress-section {
                min-height: 58px;
                display: flex;
                flex-direction: column;
                > div {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                .promotion-note {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;

                    .note-title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 4px;
                        .red-note {
                            color: #eb2121;
                        }
                    }

                    .note-content {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        background-color: #fff5bf;
                        border-radius: 4px;
                        padding: 8px;
                        color: #83630b;
                        line-height: 16px;

                        .info-icon {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .promotion-progress {
                    .progress-info {
                        font-size: 12px;
                        color: #666;
                        margin-bottom: 8px;
                        display: flex;

                        .progress-title {
                            margin-right: 8px;
                        }

                        .progress-current {
                            font-weight: 600;
                            color: #00a6ff;
                        }

                        .progress-separator {
                            margin: 0px 2px;
                        }

                        .progress-percentage {
                            margin-left: auto;
                            font-weight: 600;
                            color: #666;
                        }
                    }

                    .progress-bar {
                        width: 100%;
                        height: 6px;
                        background-color: #e3e3e3;
                        border-radius: 10px;
                        overflow: hidden;

                        .progress-bar-filled {
                            height: 100%;
                            background-color: #00a6ff;
                            border-radius: 10px;
                            transition: width 0.3s;
                        }
                    }
                }
            }

            .promotion-claimable-section {
                .claimable-status {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 12px;
                    color: #666666;
                    margin-bottom: 16px;

                    .progress-info {
                        .progress-current {
                            color: #00a6ff;
                            font-weight: 600;
                            margin-left: 8px;
                        }
                    }

                    .claimable-status-icon {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        font-size: 14px;
                        color: #00a6ff;

                        i {
                            margin-right: 4px;
                        }
                    }
                }

                .claim-button {
                    width: 100%;
                    background-color: #ffffff;
                    color: #00a6ff;
                    font-size: 16px;
                    font-weight: 600;
                    padding-top: 16px;
                    border: 0;
                    border-top: 1px solid #e3e3e3;
                    cursor: pointer;
                }
            }
        }
    }
}

/*------- NotStarted modal -------*/
/* start-promotion */
.start-promotion-modal {
    .title {
        color: #666666;
        margin-bottom: 16px;
    }

    .dropdown-arrow {
        transform: rotate(180deg);
        transition: transform 0.3s;
        margin-top: -3px;
        &.open {
            transform: rotate(0deg);
        }
    }

    .custom-dropdown {
        border-radius: 6px;
    }

    .custom-dropdown .ant-select-dropdown-menu {
        padding: 0;
    }

    /* 調整 Select 下拉菜單的文字靠左 */
    .custom-dropdown .ant-select-dropdown-menu-item {
        padding: 12px 16px;
        color: #666666;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
    }

    /* 選中的項目背景顏色與 hover 樣式 */
    .custom-dropdown .ant-select-dropdown-menu-item-selected {
        color: #222;
        background-color: #efeff4;
    }

    /* 自定義勾選圖標樣式 */
    .custom-check-icon {
        width: 20px;
        height: 20px;
    }

    /* 避免打勾符號出現在已選中的值上方 */
    .custom-select .custom-check-icon {
        display: none;
    }

    /* 錯誤訊息樣式 */
    .error-message {
        margin-top: 8px;
        color: #ff2424;
        font-size: 12px;
        white-space: pre-line;
    }

    .action-buttons {
        margin-top: 32px;
    }
}
/* ConflictModal 的外層樣式 */
.conflict-modal {
    .ant-modal-header {
        padding-bottom: 20px;
    }
    .ant-modal-body {
        .conflict-modal-content {
            .warning-icon {
                text-align: center;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .main-message {
                margin: 0 0 10px;
                font-size: 16px;
                color: #666666;
                text-align: center;
                white-space: pre-line;

                strong {
                    font-weight: 400;
                    color: #222222;
                }
            }

            /* 灰色背景區塊 */
            .bonus-info {
                background-color: #efeff4;
                border-radius: 8px;
                padding: 16px;
                margin: 20px 0;
                text-align: left;

                /* 項目分隔線 */
                p {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;
                    color: #999999;
                    margin: 0;

                    &:not(:last-child) {
                        margin-bottom: 16px;
                        border-bottom: 1px solid #e3e3e3;
                        padding-bottom: 16px;
                    }

                    span {
                        color: #222;
                    }
                }
            }

            .additional-info {
                color: #666;
                margin-bottom: 20px;
            }

            .checkbox-container {
                margin-bottom: 20px;

                .custom-checkbox-label {
                    strong {
                        color: #222;
                    }
                }
            }
        }
    }
}
/*------- InProgress modal -------*/
.cancellation-modal {
    &-content {
        text-align: center;

        .warning-icon {
            margin-bottom: 24px;

            img {
                width: 40px;
                height: 40px;
            }
        }

        .main-message {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 20px;

            strong {
                font-weight: 400;
                color: #222222;
            }
        }

        .additional-info {
            font-size: 16px;
            color: #666666;
            margin-bottom: 20px;
            padding-top: 20px;
            border-top: 1px solid #e3e3e3;

            p {
                margin: 0;
            }

            strong {
                font-weight: 400;
                color: #222222;
            }

            .numbered-list {
                padding: 0;
                padding-left: 20px;
                margin: 0;
                list-style-type: decimal; /* 使用數字序號 */
                text-align: left;

                li {
                    list-style: decimal;
                }
            }
        }

        .checkbox-container {
            text-align: left;
            margin-bottom: 24px;

            .custom-checkbox-label {
                strong {
                    color: #222;
                }
            }
        }
    }
}
/*------- claimable modal -------*/
.claim-bonus-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .success-icon {
        margin-bottom: 24px;

        img {
            width: 40px;
            height: 40px;
        }
    }

    .main-message {
        font-size: 16px;
        color: #666666;
        margin-bottom: 32px;
    }

    .action-buttons {
        width: 100%;
    }
}
