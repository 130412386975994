/*
  From M2 Mobile /component/Common/Tabs
  Tabs | TabPane組件 Default style for Version 1.0.2
*/

.tabs_container{
  display: flex;
  flex-direction: column;
  gap:24px;
  .tabLists{
    display: flex;
    text-align: center;
    justify-content: space-around;
    position: relative;
    .tabPane_titleBox{
      display: flex;
      align-items: center;
      justify-content: center;
      gap:4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      &.active{
        .tabPane_title{
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          text-align: center;
        }
      }
      &:hover{
        filter: brightness(1.2);
        cursor: pointer;
      }
      .tabPane_title{
        display: block;
        white-space: nowrap;
        transition: all 0.3s;
        &:hover{
          cursor: pointer;
        }
      }
      .prefix_icon{
        position: relative;
        width: 16px;
        height: 14px;
      }
    }
    .tabInk{
      position: absolute;
      width: 27.85px;
      height: 3px;
      background-color: #FFF;
      bottom: -5px;
      transform: translateX(-50%);
      transition: all 0.2s;
      z-index: 10;
    }
    .buttomLine{
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #FFF;
      bottom:-10px;
      z-index: 1;
    }
    &.switch{
      justify-content: center;
      background-color: #0ea0f0;
      height: 28px;
      width: max-content;
      margin: 0 auto;
      border-radius: 20px;
      position: relative;
      .tabPane_titleBox{
        flex-basis: unset !important;
        z-index: 10;
        padding: 0 15px;
        .tabPane_title{
          color: #ccedff !important;
          font-size: 14px;
          font-weight: 400;
        }
        &.active{
          .tabPane_title{
            color:#00a6ff !important;
          }
        }
      } 
      .switcher{
        position: absolute;
        width: 50px;
        height: 28px;
        background-color: #FFF;
        border-radius: 20px;
        transition: all 0.3s
      }
    }
  }
  .tabPanes{
    display: flex;
    transition: all 0.5s;
    align-items: flex-start;
    .tabPane_content{
      overflow-y: auto;
      flex-shrink: 0;
      flex-basis: 100%;
      transition: all 0.1s;
      opacity: 0;
      pointer-events: none;
      &.active{
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
}


