// 申請優惠彈窗
.commonModal.ant-modal-confirm {
    width: 400px !important;
    .ant-modal-close {
        display: none;
    }
    .ant-modal-body {
        padding: 0;
    }
    .ant-modal-confirm-body {
        i {
            display: none;
        }
    }
    .ant-modal-confirm-title {
        height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        padding-top: 14.5px;
        background: #00a6ff 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
    }
    .note {
        height: 20px;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
    }
    .ant-modal-confirm-btns {
        padding: 0 30px 30px 30px;
    }
}
