.date-picker-container {
    position: relative;
    height: 40px;
    display: flex;
    gap: 16px;
}

.date-input-container {
    width: 240px;
    display: flex;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    background: white;
    align-items: center;
    padding: 8px 16px;
    line-height: 22px;
    justify-content: space-between;
	cursor: pointer;
}

.date-input {
    font-size: 16px;
}

.icon {
    font-size: 18px;
}

.search-btn {
    background: #00a6ff;
    color: white;
    border: none;
    padding: 8px 24px;
    border-radius: 6px;
    cursor: pointer;
}

.search-btn:hover {
    background: #008ace;
}

.date-picker-dropdown {
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    text-align: center;
    overflow: hidden;
}

.dropdown-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    height: 248px; /* 確保容器高度正確 */
    overflow: hidden;
    padding: 16px;
}

.highlight-bar {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 44px;
    background: #e6f6ff;
    transform: translateY(-50%);
    border-radius: 6px;
    margin: 0px 16px;
    z-index: 1;
    pointer-events: none;
    mix-blend-mode: multiply;
}

.gradient-overlay {
    position: absolute;
    left: 0;
    right: 0;
    height: calc((100% - 44px) / 2);
    pointer-events: none;
    z-index: 2;
}

.gradient-overlay.top {
    top: 0;
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.4) 10%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0.6) 90%,
        rgba(255, 255, 255, 1) 90%,
    );
}

.gradient-overlay.bottom {
    bottom: 0;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.4) 10%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0.6) 50%,
        rgba(255, 255, 255, 0.6) 90%,
        rgba(255, 255, 255, 1) 90%,
    );
}

.dropdown {
    width: 112px;
    max-height: 216px;
    overflow-y: hidden; /* 禁止滾動 */
    border-radius: 5px;
    user-select: none;
    cursor: grab;
    display: inline-block;
    text-align: center;
    position: relative;
    padding-top: calc((216px - 44px) / 2);
    padding-bottom: calc((216px - 44px) / 2);
}

.dropdown-content {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dropdown-content li {
    padding: 10px;
    background: white;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #222222;
}

// .dropdown-content li.selected {
//     color: white;
//     font-weight: bold;
// }

.confirm-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background-color: #efeff4;
}

.confirm-btn {
    width: 140px;
    padding: 8px 50px;
    background: #00a6ff;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
}

.confirm-btn:hover {
    background: #008ace;
}
