#download_vendor {
    .dowing {
        width: 130px;
        height: 50px;
        background: #f92d2d 0% 0% no-repeat padding-box;
        border-radius: 10px;
        border: 0;
        color: white;
        cursor: pointer;
    }

    .box {
        display: flex;
        justify-content: center;
        align-items: center;

        small {
            padding-left: 10px;
        }

        span {
            font: Bold 17px/30px Microsoft YaHei UI;
        }

        div {
            text-align: center;
        }

        .l {
            flex: 0.5;
        }

        .c {
            flex: 1;
            text-align: left;
        }

        .r {
            flex: 1;
        }
    }

    h2 {
        font: Bold 23px/30px Microsoft YaHei UI;
        letter-spacing: 0;
        color: #222222;
        margin: 25px 10px 10px;
        text-align: left;
    }

    .content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;

        li {
            width: 50%;
            height: 150px;
            padding: 10px;

            .box {
                width: 100%;
                height: 100%;
                background: #ffffff 0% 0% no-repeat padding-box;
            }
        }
    }

    .down-wrap {
        min-height: 800px;
        background: url(/img/down/bg.jpg) center top no-repeat;
        background-size: cover;
    }

    .list:last-child {
        margin-bottom: 30px;
    }
}
