// 登入(歡迎您)跳窗
.modal-pubilc {
    text-align: center;

    .ant-tabs {
        overflow: visible;
    }

    // &.ant-modal {
    //     height: 386px;
    // }

    .ant-modal-header {
        background: #00a5fd;
        border-radius: 20px 20px 0 0;

        .ant-modal-title {
            color: #fff;
            font-size: 20px;
            height: 27px;
            text-align: center;
        }
    }

    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-body {
            padding: 28px 30px;

            .ant-input-prefix {
                img {
                    width: 20px;
                    height: 20px;
                }
            }

            .ant-input-affix-wrapper .ant-input:not(:first-child) {
                padding-left: 38px;
            }

            .IputBox {
                margin-bottom: 16px;
            }

            .ant-form {
                &.ant-form-horizontal {
                    .ant-form-item-control.has-error {
                        display: flex;
                        flex-direction: column;

                        .ant-form-explain {
                            order: -1;
                            margin-top: 0px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .ant-btn-primary {
                font-weight: 700;
            }

            .login-error {
                margin-bottom: 10px;
                font-size: 12px;
                text-align: left;
                letter-spacing: 0px;
                color: #eb2121;
                background: #fee5e5 0% 0% no-repeat padding-box;
                border-radius: 8px;
                opacity: 1;
                padding: 10px;
            }
        }

        button:disabled {
            background-color: #ccc;
            border-color: #ccc;
            color: #fff;
        }
    }

    &.login-and-registration {
        white-space: pre-line;
        .ant-modal-header {
            padding: 21px 0px 12px 0px;
        }

        .ant-tabs-nav-wrap {
            width: 299px;
            height: 40px;
        }

        .ant-modal-content {
            border-radius: 20px;

            .ant-modal-body {
                padding: 32px 50px;
                .ant-form-item {
                    margin-bottom: 0px;
                }
                .ant-input {
                    &:disabled {
                        border-color: #e0e0e0;
                    }
                }
                .IputBox {
                    margin-bottom: 10px;
                    .hasError {
                        > input {
                            border-color: #ff4d4f;
                        }
                    }
                }

                .ant-form {
                    &.ant-form-horizontal {
                        .ant-form-item-control.has-error {
                            display: flex;
                            flex-direction: column;

                            .ant-form-explain {
                                order: -1;
                                margin-top: 0px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.ForgotPwdModal {
        .ant-modal-header {
            padding: 21px 24px 12px 24px;
        }
        .ant-modal-body {
            padding: 30px 50px;
        }
    }
    .Forget-pwd-tabs {
        // padding: 10px 20px;

        .ant-tabs-bar {
            margin-bottom: 32px;
        }

        .ant-tabs-ink-bar {
            height: 98%;
            z-index: -1;
            border-radius: 50px;
        }

        .ant-tabs-tabpane {
            label {
                font-size: 12px;
                text-align: left;
            }
        }

        .IputBox {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .invalid-input-error {
            margin-bottom: 10px;
            font-size: 12px;
            text-align: left;
            letter-spacing: 0px;
            color: #eb2121;
            background: #fee5e5 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            padding: 10px;
        }

        .invalid-input-box-error {
            border-color: #ff4d4f;
        }

        .error {
            margin-top: 10px;
            font-size: 12px;
            text-align: left;
            letter-spacing: 0px;
            color: #eb2121;
            background: #fee5e5 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            padding: 10px;
        }

        label {
            display: block;
            margin: 0px 0px 5px 5px;
        }

        &.tabs-modal-pubilc {
            overflow: hidden;
            .ant-tabs-content {
                .ant-tabs-tabpane {
                    .IputBox {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        margin-bottom: 32px;

                        &.forgetuser-input {
                            gap: 0;

                            button {
                                background-color: #00a6ff;
                            }
                        }
                    }
                }
            }
        }
    }

    .IputBox {
        .registerPhone.ant-input-affix-wrapper {
            .ant-input:not(:first-child) {
                padding-left: 68px;
            }
        }
    }

    .IputBox.fix-not-required-text {
        position: relative;
        .not-required-text {
            position: absolute;
            right: 20px;
            bottom: 10px;
            letter-spacing: 0px;
            color: #999999;
            opacity: 1;
            font-size: 14px;
        }
    }

    // 切換登入/註冊Tab
    .tabs-modal-pubilc {
        .ant-tabs-bar {
            background-color: #7676801f;
            border-radius: 50px;
            margin-bottom: 30px;

            .ant-tabs-nav-container {
                .ant-tabs-nav-scroll {
                    width: 100%;

                    .ant-tabs-nav {
                        width: 100%;
                        z-index: 10;

                        // tab選項box
                        > div:not(:last-child) {
                            width: 100%;
                            display: flex;

                            // 單個tab
                            > div {
                                width: 100%;
                                margin: 0;
                            }

                            .ant-tabs-tab-active {
                                color: white !important;
                            }
                        }

                        .ant-tabs-ink-bar {
                            height: 98%;
                            z-index: -1;
                            border-radius: 50px;
                        }
                    }
                }
            }
        }
    }

    .link-box {
        text-align: right;
        height: 16px;
        margin-bottom: 30px;

        button:first-child {
            display: flex;
            flex-direction: column;
            justify-content: flex-start; //css
            padding: 0;
            height: 16px;
            margin-left: auto;
        }

        span {
            font-size: 12px;
        }
    }

    .protocol-box {
        width: 318px;
        // white-space: nowrap;
        display: flex;
        justify-content: center;
        margin-left: -9px;
        .protocol {
            font-size: 12px;
            // transform: scale(0.85);
            text-align: center;
            margin-top: 20px;
        }
    }

    &.OTP-modal {
        // otp 驗證彈窗
        .ant-modal-content {
            background-color: white;
        }
        .ant-modal-body {
            padding: 30px 32px 24px 32px;
            background-color: white;
            border-radius: 20px;

            .otp-modal-banner {
                margin-left: -32px;
                margin-top: -30px;
            }

            .otp-modal-description {
                //text-align: justify;
                margin: 20px 0;
                padding: 10px;
                background-color: white;
                border-radius: 10px;
                font-size: 14px;

                h4 {
                    font-weight: 600;
                    margin: 10px 0;
                }

                .otp-cs {
                    color: #1c8eff;
                    cursor: pointer;
                }
            }

            .verify-icon-wrap {
                width: 70px;
                height: 70px;
                margin: 0 auto;
                display: block;
                background: white;
                border-radius: 10px;
                position: relative;
                cursor: pointer;
                transition: 0.5s;

                .verify-icon {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &.red-email,
                &.red-phone,
                &.red-cs {
                    background: lighten(#00a6ff, 20%);
                }

                &:checked {
                    background: darken(#00a6ff, 5%);
                }
            }
            .OtpList {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .otpBox {
                background-color: white;
                padding: 10px;
                border-radius: 8px;
                text-align: center;
            }
            .otpBtn {
                margin: 30px auto 10px;
                color: #00a6ff;
                background: #fff;
                border: 1px solid #00a6ff;
                border-radius: 8px;
                &.readMore {
                    color: #fff;
                    background: #00a6ff;
                    margin-top: 10px;
                    width: 332px;
                    margin-left: 54px;
                    margin-right: 54px;
                }
            }

            .verification-form-wrap {
                .timeTip {
                    width: 100%;
                    text-align: center;
                }

                .error-tip {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    text-align: left;
                    color: #f00;
                    font-size: 12px;
                    white-space: pre-line;
                }

                .text-tip span {
                    color: #1c8eff;
                    cursor: pointer;
                }

                .tlc-input-disabled {
                    .icon-text-eyes {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }

                    .icon-password-eyes {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    &.password-time-out {
        .verification-form-wrap {
            .otpPwdModal-Item {
                .text {
                    font-size: 14px;
                    color: #222222;
                    margin-bottom: 14px;
                    text-align: center;
                }
            }
        }
    }

    &.CTC-INVOICE-AUT {
        .ant-col-12 {
            text-align: center;
            border: 1px solid #00a6ff;
            border-radius: 6px;
            width: 45%;
            height: 40px;
            line-height: 40px;
            margin: 6% 2.5% 0;
            cursor: pointer;

            &:first-child {
                color: #00a6ff;
            }

            &:last-child {
                color: #fff;
                background-color: #00a6ff;
            }
        }
    }
}

.Spin_modal {
    top: 35%;

    .ant-modal-header {
        display: none;
    }

    .ant-modal-body {
        text-align: center;
        height: 95px;

        .ant-spin {
            padding-top: 20%;
            border-radius: 8px;
        }
    }
}

.ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
}

.ant-message {
    z-index: 10000 !important;
}

//银行卡资讯
.bankDetailsModal {
    .ant-modal-content {
        border-radius: 20px;
    }
    .ant-modal-header {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        text-align: center;
        .ant-modal-title {
            font-size: 20px;
        }
    }
    .ant-modal-body {
        padding: 16px 30px 32px;
        .item {
            width: 100%;
            min-height: 50px;
            border-bottom: 1px solid #f3f3f3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #666666;
            padding: 0px 12px;
            span {
                &:last-child {
                    color: #222222;
                    text-align: right;
                }
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .bank-info {
        margin-bottom: 25px;
        .item {
            span {
                &:first-child {
                    min-width: 88px;
                }
            }
        }
    }
    .withdraw-info {
        label {
            color: #999999;
            font-size: 12px;
        }

        .text1 {
            font-size: 14px;
            margin-bottom: 10px;
        }

        .text2 {
            font-size: 12px;
            height: 20px;
            line-height: 20px;
        }

        .text3 {
            font-size: 12px;
            color: #999999;
            border-bottom: 1px solid #1a1a1a1a;
            height: 20px;
            line-height: 20px;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }

        .text4,
        .text5 {
            width: 100%;
            color: #999999;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #1a1a1a1a;
        }

        .text6 {
            font-size: 12px;
            background: #fff5bf;
            color: #83630b;
            padding: 0.5rem;
            border-radius: 10px;
            ul {
                padding-left: 12px;
                li {
                    list-style: decimal;
                }
            }
            // span {
            //     color: #f00;
            // }
        }
        .startDate-Item {
            position: relative;

            .clearItem {
                position: absolute;
                right: 10px;
                top: -50px;
                color: #1c8eff;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
    .btn {
        margin-top: 25px;
        display: flex;
        gap: 16px;
        flex-direction: column;
    }
}

.bankAccountModals {
    .ant-modal-content {
        position: relative;
        border-radius: 20px;

        .ant-modal-header {
            border-radius: 20px 20px 0 0;

            .ant-modal-title {
                font-weight: normal;
            }
        }

        .ant-modal-body {
            padding: 0 40px 20px 40px;

            .hoverShow {
                display: none;
                position: relative;

                p {
                    line-height: 16px;
                    height: 16px;
                }

                span {
                    width: 0;
                    height: 0;
                    border-width: 0 10px 10px;
                    border-style: solid;
                    border-color: transparent transparent black;
                    position: absolute;
                    left: 46.5%;
                    top: -10px;
                }
            }

            .iconTip {
                img {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    position: absolute;
                    left: 63%;
                    top: -39px;
                    cursor: pointer;
                }
            }

            .iconTip:hover .hoverShow {
                display: block;
                width: 350px;
                height: 180px;
                color: #fff;
                font-size: 12px;
                background-color: black;
                position: absolute;
                left: 38%;
                top: -5px;
                z-index: 100;
                padding: 10px 8px;
                border-radius: 10px;
            }

            .ant-row {
                margin-left: 0px !important;
                margin-right: 0px !important;
                margin-bottom: 5px !important;

                .ant-col-12 {
                    margin-bottom: 35px;
                    font-size: 12px;
                    height: 120px;

                    &:nth-child(odd) {
                        padding-left: 0px !important;
                        padding-right: 20px !important;
                    }

                    &:nth-child(even) {
                        padding-left: 20px !important;
                        padding-right: 0px !important;
                    }

                    &:last-child {
                        padding-top: 21px;
                    }

                    span {
                        color: #666666;
                    }

                    label {
                        font-size: 12px;
                        color: #222222;
                    }

                    .ant-form-item-control .ant-form-item-children {
                        position: relative;

                        .money-icon {
                            position: absolute;
                            left: 10px;
                        }

                        .ant-input {
                            padding-left: 20px;
                        }

                        .baifen-icon {
                            position: absolute;
                            right: 10px;
                        }
                    }
                }
            }

            .settingRequirement {
                padding: 12px 16px;
            }

            .btnRow-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;

                .ant-col-6 {
                    text-align: center;
                    border-radius: 6px;
                    height: 40px;
                    line-height: 40px;
                    cursor: pointer;

                    &:first-child {
                        color: #000;
                        border: 1px solid #cccccc;
                    }

                    &:last-child {
                    }
                }
            }

            .ant-btn {
                width: 320px;
            }

            .btnactive {
                color: #fff;
                background-color: #00a6ff;
            }

            .btnnoactive {
                color: #fff;
                background-color: #cccccc;
            }
        }
    }
}

.tip-modal .ant-modal-body {
    background-color: transparent !important;
    padding: 0 !important;
}

.ant-modal.ant-modal-confirm.right-angle .ant-modal-content {
    border-radius: 0;
}

//滑动验证
.fpcaptch-modal {
    z-index: 2002;
    .ant-modal-close-x {
        color: #222222;
        filter: invert(1);
    }
    .ant-modal-body {
        padding: 16px 16px 10px;
    }

    .fpcontent {
        .fpSection {
            width: 100%;
            height: 150px;
            position: relative;

            .moveElement {
                width: 60px;
                height: 60px;
                position: relative;
                cursor: pointer;
            }

            .info {
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: #000;
                opacity: 0.7;
                left: 0;
                top: 0;
                color: #fff;

                img {
                    margin: 10px 0 0 90%;
                    width: 18px;
                    height: auto;
                    cursor: pointer;
                }

                p {
                    padding: 15px;
                }
            }

            .verifySuccess {
                width: 100%;
                height: 40px;
                line-height: 40px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #10a0004a;
                color: #42d200;

                img {
                    width: 18px;
                    height: auto;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }

            .verifyFail {
                width: 100%;
                height: 40px;
                line-height: 40px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #f92d2d61;
                color: #f00;

                img {
                    width: 18px;
                    height: auto;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }

        .fpFooter {
            margin: 10px 0 0;

            img {
                cursor: pointer;

                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }
}

.uploadImages-modal {
    .ant-modal-title {
        font-size: 16px;
    }

    .tipTextp {
        font-size: 14px;
        color: #222222;
    }

    ul li {
        margin-bottom: 10px;

        & > span {
            font-size: 12px;
            color: #666;
        }

        .ant-btn {
            height: 40px;
            background-color: #f3f3f3;
            margin-top: 8px;

            > span {
                color: #1c8eff;
            }

            &:hover,
            &:focus {
                border-color: 1px solid #ccc;
            }
        }

        .conbtn {
            width: 100%;
            height: 43px;
            border-radius: 8px;
            padding: 0 10px;
            border: 1px solid #e0e0e0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #f3f3f3;

            .ant-btn:hover,
            .ant-btn:focus {
                border: 1px solid #e0e0e0;
                color: #999999;
            }

            span {
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            div {
                width: 20px;
                height: 20px;
                background-color: #00000066;
                border-radius: 13px;
                line-height: 18px;
                text-align: center;

                img {
                    width: 12px;
                    height: 12px;
                    vertical-align: middle;
                }
            }
        }

        &:last-child {
            .ant-btn {
                width: 46%;
                font-size: 14px;
                border-radius: 19px;

                &:first-child {
                    margin-right: 4%;

                    > span {
                        color: #666666;
                    }
                }

                &:last-child {
                    background-color: #cccccc;
                    margin-left: 4%;

                    > span {
                        color: #fff;
                    }
                }

                &.active {
                    background-color: #00a6ff;
                    color: #ffffff;
                }
            }
        }
    }
}

// USDT提款 存款
.UsdtTeachModalStyle .ant-modal-body {
    padding: 0;

    .ant-tabs-bar {
        border-bottom: none;
        margin: 0;
        padding-top: 15px;
    }
    .ant-tabs-tab {
        font-weight: bold;
    }
}

.UsdtTeachModalStyle .ant-modal-close-x {
    font-size: 20px;
    color: white;
    height: 51px;
    line-height: 51px;
}

.Otptxt {
    color: #222222;
    font-weight: bold;
}

// 忘記密碼/ 忘記用戶名
.confirm-modal-of-public,
.confirm-modal-of-forgetuser {
    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-header {
            .ant-modal-title {
                text-align: center;
            }
        }

        .ant-modal-body {
            padding: 0;
            text-align: center;
        }

        .ant-modal-confirm-body {
            .ant-modal-confirm-title {
                color: #fff;
                background-color: #00a6ff;
                padding: 19px 100px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                font-size: 20px;
            }

            .ant-modal-confirm-content {
                padding-bottom: 0;
                margin-bottom: 39px;
                color: #222222;
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        .ant-modal-confirm-btns {
            margin: 0 30px 30px 30px;
        }
    }
}

.wallet-deposit-warning {
    &.confirm-modal-of-public {
        .ant-modal-content {
            .ant-modal-confirm-body {
                .ant-modal-confirm-content {
                    margin-bottom: 0;
                    padding: 0 25px;
                }
            }
        }
    }
}

.ReferModal {
    .note {
        color: #222222;
        text-align: left;
    }
    .RecommendedGames {
        text-align: left;
        padding-top: 15px;
        margin-bottom: 11px;
    }
}

// 驗證超過次數Modal
.exceed-modal {
    .ant-modal-content {
        .ant-modal-body {
            .otp-warn {
                display: block;
                margin: 0 auto;
            }
            .otp-modal-title {
                font-size: 18px;
                margin: 13px auto;
                font-weight: 500;
                text-align: center;
                color: #222222;
            }
            .otp-cs-tip-exceed {
                // 驗證超過次數content
                text-align: center;
                color: #999;
                margin: 19px;
            }
            .exceed-btn-wrap {
                display: flex;
                column-gap: 20px;
            }
        }
    }
}

// general large feedback modal
.large-feedback-status-modal {
    .anticon {
        display: none;
    }
    .ant-modal-content {
        .ant-modal-body {
            padding: 20px 30px 30px;
            text-align: center;
            .ant-modal-confirm-body .ant-modal-confirm-content {
                margin: 0px;
                img {
                    width: 60px;
                    height: 60px;
                }
                h3 {
                    margin: 31px 0px 0px;
                    font-size: 18px;
                }
                .note {
                    font-size: 16px;
                }
            }
            .ant-modal-confirm-btns {
                display: flex;
                gap: 15px;
                margin-top: 15px;
                button {
                    margin: 0px;
                    width: 120px;
                    height: 40px;
                }
            }
        }
    }
}

.ant-modal-content {
    box-shadow: unset;
}

.SecurityAnnouncement {
    .ant-modal-content {
        .ant-modal-header {
            text-align: center;
            border-bottom: 0px;
        }
        .ant-modal-body {
            padding: 30px;
            background-color: white !important;

            .otp-modal-banner {
                margin-left: -30px;
            }

            .otp-modal-description {
                margin: 30px 0 20px 0;
                padding: 0;
                color: #222;
            }
        }
    }
}
.StepsBox {
    .ant-steps-item-wait .ant-steps-item-icon {
        border-color: #ccc !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: #ccc !important;
    }
}

.loginOTPModal {
    .ant-modal-content {
        .ant-modal-header {
            text-align: center;
        }
        .ant-modal-body {
            padding: 22px 30px 30px 30px;
            background-color: #efeff4;
            border-radius: 0 0 20px 20px;

            .otp-modal-description {
                padding: 20px;
                margin: 0 0 20px 0;
                color: #000;

                .ant-btn {
                    &.otpBtn {
                        height: 40px;
                        margin: 16px 0 0 0;
                    }
                }
            }

            .OtpList {
                justify-content: center;
                gap: 16px;

                .otpBox {
                    width: 100%;
                    padding: 20px 16px;

                    .ant-btn-primary {
                        width: 130px;
                        height: 44px;
                        font-weight: normal;
                    }
                }
            }

            .ant-btn-background-ghost {
                height: 40px;
            }
        }
    }
}

// 信箱、手機驗證modal
.verify-modal {
    .ant-modal-header {
        .ant-modal-title {
            font-size: 20px;
        }
    }
    .ant-modal-body {
        .ant-form-item-required {
            &::before {
                content: "";
            }
        }
        .StepsBox {
            width: 168px;
            .ant-steps-item {
                margin-right: 0px !important;
                .ant-steps-item-container {
                    .ant-steps-item-icon {
                        margin-right: 0px;
                    }
                    .ant-steps-item-title::after {
                        height: 2px;
                        left: 0px;
                    }
                }
            }
            .ant-steps-item-process {
                .ant-steps-item-container {
                    .ant-steps-item-icon {
                        background-color: #00a6ff;
                        .ant-steps-icon {
                            color: #ffffff;
                        }
                    }
                    .ant-steps-item-title::after {
                        background-color: #d4d4d4;
                    }
                }
            }
        }
        label {
            font-size: 12px;
        }
        .verify-remaining {
            font-size: 12px;
            margin-bottom: 15px;
        }
        .TextLightGrey {
            margin: -8px 0px 16px;
        }
        .OTP-input {
            &.has-error {
                input {
                    border-color: #eb2121;
                }
            }
            input {
                font-size: 12px;
                padding: 10.5px 7px;
            }
            .ant-input-group-addon:last-child {
                width: 100px;
                padding: 0px;
                > div {
                    width: 100%;
                    padding: 5px 0px;
                    line-height: 33px;
                }
                .resend-disabled {
                    color: #fff;
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
        button.changeVerify {
            color: #cccccc;
            background-color: #fff;
            border-color: #cccccc;
            &.abled {
                color: #00a5fd;
                background-color: #fff;
                border-color: #00a5fd;
            }
        }
        div.changeVerify {
            text-align: center;
            > span {
                color: #00a6ff;
                cursor: pointer;
                border-bottom: 1px solid #00a6ff;
            }
        }
        .realname-image {
            margin: 11px 0 5px;
            width: 100%;
            text-align: center;
        }
        .verify-realname {
            color: #666666;
            label {
                color: #222222;
            }
            input,
            button {
                height: 44px;
            }
            input::placeholder {
                color: #999999;
            }
            p {
                margin-bottom: 16px;
            }
            .verify-realname-box {
                p {
                    font-size: 12px;
                }
                .verify-realname-input {
                    margin-bottom: 16px;
                    .ant-form-explain {
                        margin-bottom: 0px;
                    }
                }
            }
            .verify-realname-btns {
                display: flex;
                gap: 10px;
                button {
                    width: 100%;
                }
            }
        }
    }
}

.StepsBox {
    width: 200px;
    margin: 0 auto;
    padding-bottom: 20px;
}

.forgetPwdModal {
    .ant-modal-content {
        .ant-modal-close {
            display: none;
        }
        .ant-modal-body {
            .ant-modal-confirm-body .ant-modal-confirm-content {
                padding: 0 15px;
                margin: 0;
                min-height: 97px;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: pre-line;
            }
            .ant-modal-confirm-btns {
                .ant-btn:first-child {
                    width: 340px;
                    height: 40px;
                }
            }
        }
    }
}

.login-exceed-modal {
    .ant-modal-content {
        .ant-modal-close {
            display: none;
        }
        .ant-modal-body {
            .ant-modal-confirm-body {
                .ant-modal-confirm-title {
                    padding-bottom: 14px;
                }
                .ant-modal-confirm-content {
                    margin: 20px 0;
                    p {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

// 中心支付通用info樣式
.info-general-modal {
    .ant-modal-content {
        border-radius: 20px;
        // .ant-modal-close {
        //     display: block;
        // }
        .ant-modal-body {
            padding: 0;
            text-align: center;
        }
        .ant-modal-confirm-body {
            .ant-modal-confirm-title {
                color: #fff;
                background-color: #00a6ff;
                padding: 18px 0px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                font-size: 20px;
                height: 60px;
                font-weight: 500;
            }
            .ant-modal-confirm-content {
                padding-bottom: 0;
                margin: 25px 0;
                color: #222222;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .ant-modal-confirm-btns {
            margin: 0 30px 30px 30px;
            .ant-btn {
                width: 340px;
                height: 40px;
            }
        }
    }
}
// 中心支付通用confirm樣式
.confirm-general-modal {
    .ant-modal-content {
        border-radius: 20px;
        .ant-modal-close {
            display: none;
        }
        .ant-modal-body {
            padding: 0;
            text-align: center;
        }
        .ant-modal-confirm-body {
            .ant-modal-confirm-title {
                color: #fff;
                background-color: #00a6ff;
                padding: 18px 0px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                font-size: 20px;
                height: 60px;
                font-weight: 500;
            }
            .ant-modal-confirm-content {
                padding-bottom: 0;
                margin: 25px 0;
                color: #222222;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .ant-modal-confirm-btns {
            margin: 0 30px 30px 30px;
            display: flex;
            gap: 20px;
            .ant-btn {
                width: 100%;
                height: 40px;
                margin: 0;
                &:first-child {
                    color: #00a6ff;
                    &:hover,
                    &:focus {
                        color: #29bbff;
                        border-color: #29bbff;
                    }
                }
            }
        }
    }
}

//通用 confirm 樣式
.general-confirm-modal {
    .ant-modal-close {
        display: none;
    }
    .ant-modal-confirm-content {
        margin: 40px 0 35px 0;
        font-size: 16px;
    }
    .ant-modal-confirm-btns {
        margin: 0 30px 30px 30px;
        display: flex;
        gap: 20px;
        .ant-btn {
            width: 100%;
            height: 40px;
            margin: 0;
        }
        .ant-btn:first-child {
            border-color: #00a6ff;
            color: #00a6ff;
            &:hover,
            &:focus {
                color: #29bbff;
                border-color: #29bbff;
            }
        }
    }
}
//新會員Promotion彈窗，僅註冊後首次登入出現
.newMember__promotion__modal {
    .headerImg {
        width: 100%;
        height: 210px;
    }
    main {
        padding: 32px 72px 22px;
        .promotionList {
            display: flex;
            gap: 10px;
            flex-direction: column;
            margin-bottom: 20px;
            li {
                display: flex;
                align-items: center;
                height: 60px;
                border-radius: 10px;
                padding: 10px;
                color: #464646;
                background-color: #f5f5f5;
            }
        }
        .checkbox_allow_cs {
            margin-bottom: 18px;
            color: #000000;
            .ant-checkbox-inner {
                border-radius: 3px;
            }
        }
        .later {
            text-align: center;
            color: #00a6ff;
            margin-top: 15px;
            text-decoration: underline;
            cursor: pointer;
        }
        p {
            color: #727272;
            margin-bottom: 20px;
        }
    }
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
        .ant-modal-body {
            padding: 0;
        }
    }
}
.announcement-modal {
    .ant-modal-body {
        padding: 28px 30px;
    }
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
    }
    .title {
        h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }
    }
    .content {
        color: #222;
    }
    .customer-service {
        img {
            margin-right: 8px;
        }
    }
}

.smartico-maintenance-modal {
    .ant-modal-body {
        padding: 28px 28px 12px;
        .content {
            text-align: center;
        }
    }
    .ant-modal-footer {
        padding: 16px;
    }
}

.show-result-popup {
    .ant-modal-content {
        .ant-modal-close-x {
            display: none;
        }
        .ant-modal-body {
            padding: 52px 40px 40px;
            .ant-modal-confirm-content {
                margin-top: 16px;
                padding: 0px;
                white-space: pre-line;
            }
            .ant-modal-confirm-btns {
                display: flex;
                margin-top: 32px;
                gap: 16px;
                .ant-btn:first-child {
                    color: #00a6ff;
                    border-color: #00a6ff;
                }
                .ant-btn.ant-btn-primary {
                    color: #ffffff;
                }
                .ant-btn {
                    width: 100%;
                    height: 44px;
                    box-shadow: none;
                    margin: 0px;
                }
            }
        }
    }
}

// oneWallet white background pop-up
.custom-modal-white-bg {
    .ant-modal-close-icon {
        background-image: url(/th/img/icon/icon-gray-close.svg);
        width: 14px;
        height: 14px;
    }
    .ant-modal-header {
        background-color: #fff;
        padding: 44px 40px 32px;
        .ant-modal-title {
            font-size: 20px;
            font-weight: 600;
            color: #222;
        }
    }
    .ant-modal-body {
        padding: 0 40px 40px;
    }
    .ant-form-vertical {
        .ant-form-item {
            padding: 0;
        }
        .ant-form-item-label {
            font-weight: 600;
            line-height: normal;
            margin-bottom: 8px;
            padding: 0;
        }
    }
    .ant-input,
    .ant-select-lg .ant-select-selection {
        border-radius: 6px;
        border: 1px solid #e3e3e3;
    }
    .ant-input-lg,
    .ant-select-lg .ant-select-selection,
    .ant-btn {
        height: 48px;
    }
    .ant-input {
        color: #666;
    }
    .ant-input[disabled] {
        color: #999999;
        background-color: #efeff4;
        border: 1px solid #e3e3e3;
    }
    .has-error .ant-form-explain {
        padding: 0;
        background-color: transparent;
        white-space: pre-line;
        font-size: 12px;
        color: #ff2424;
    }
    .action-buttons {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .ant-btn {
        border-radius: 6px;
    }
    .ant-btn-primary[disabled] {
        color: #999999;
        background-color: #e3e3e3;
        border: 1px solid #e3e3e3;
    }
}
