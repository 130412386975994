.DailyGiftAddress {
    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;

            .ant-modal-title {
                color: #fff;
                text-align: center;
                font-size: 16px;
            }
        }

        .ant-modal-body {
            padding: 16px 30px 30px;
            display: flex;
            flex-direction: column;
        }
    }

    .DailyGiftAddress-info {
        margin-bottom: 20px;
        width: 340px;
        /* UI Properties */
        background: #fff5bf 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        padding: 12px 16px;
        text-align: left;
        font: normal normal normal 12px/20px "Noto Sans Thai", Arial, san-serif;
        letter-spacing: 0px;
        color: #83630b;
    }
    .DailyGiftAddress-list {
        display: flex;
        align-items: center;
        width: 340px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 10px;
        opacity: 1;
        padding: 16px;
        .radioBox {
            width: 20px;
            height: 20px;
        }
        .DailyGiftAddress-list-info {
            margin-left: 8px;
            cursor: pointer;
            width: 100%;
            .info-name-edit {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                .info-name {
                    color: #222222;
                    font-size: 16px;
                    line-height: 24px; /* 150% */
                    font-weight: 600;
                    max-width: 200px;
                }
                .info-edit {
                    .default {
                        color: #0ccc3c;
                        line-height: 22px; /* 183.333% */
                        margin-right: 8px;
                        position: relative;
                        &::before {
                            content: "";
                            background-image: url("/th/img/icon/greenTick.svg");
                            background-repeat: no-repeat;
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            left: -18px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            .info-phone {
                font-size: 12px;
                color: #999999;
                margin-bottom: 8px;
            }
            .info-address {
                font-size: 12px;
                color: #666666;
            }
        }
        .default {
            color: #0ccc3c;
        }
    }
    .DailyGiftAddress-btn {
        display: flex;
        justify-content: center;
        // margin-bottom: 10px;

        button {
            letter-spacing: -2px;
            width: 340px;
            height: 40px;
            padding: 10px 0;
            border: 1px solid var(--unnamed-color-00a6ff);
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #00a6ff;
            border-radius: 10px;
            opacity: 1;
            font: normal normal normal 14px/22px "Noto Sans Thai", Arial, san-serif;
            letter-spacing: 0px;
            color: #00a6ff;
            cursor: pointer;
        }
    }
    .DailyGiftAddress-remark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 340px;
        margin-bottom: 30px;
        .remark-label {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
            color: #222222;
            font-size: 14px;
            justify-content: space-between;
        }
        textarea {
            resize: none;
            border-color: #cccccc;
            border-radius: 8px;
            padding: 12px;
            &:focus {
                outline: 1px solid #00a6ff;
            }
            &::placeholder {
                color: #999999;
            }
        }
    }
    .DailyGiftAddress-submit {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        // margin-bottom: 30px;
        button {
            padding: 10px 0;
            opacity: 1;
            font-weight: bold;
        }
    }
}
