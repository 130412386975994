.tlc-container-wrapper-Diamond {
    .link{
        color:inherit;
        text-decoration: underline;
        font-weight: 600;
    }
    .ruleList{
        padding-left: 14px;
        color:#ABA79D;
        gap:4px;
        li{
            list-style-type: decimal;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            .link{
                color:#ABA79D;
            }
        }
    }
    .tlc-sign-header {
        .notice-wrap {
            .marquee-container-wrapper {
                .marquee-title {
                    span {
                        color: #fff !important;
                    }
                }

                .marquee-container {
                    .tlc-notice-wrap {
                        background-color: transparent !important;
                        color: #fff;
                    }
                }
            }
        }

        .login-wrap {
            .good-greeting {
                color: #fff;
            }
        }
    }
    .tabs_container{
		background-color: #17191C;
		gap:0px;
		.tabLists{
            justify-content: flex-start;
            gap:20px;
			.tabPane_titleBox{
                flex-basis: unset !important;
                gap:6px;
				.tabPane_title{
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
					color: #ABA79D;
				}
				.prefix_icon{
					width: 16px;
					height: 14px;
				}
				&.star{
					.tabPane_title{
						color: #9B9EECEC;
					}
				}
				&.vip{
					.tabPane_title{
						color: #B29E57;
					}
				}
			}
			.tabInk{
				bottom: -10px;
			}
		}
		.tabPane_content{
			margin-bottom: 38px;
            color: #FFF;
            position: relative;
            .fakeButton{
                width: 89.5px;
                height: 27.5px;
                background-color: transparent;
                border: none;
                border-radius: 20px;
                position: absolute;
                bottom:0;
                cursor: pointer;
            }
            &.general{
                height: 628px;
            }
            &.star{
                height: 799px;
                img{
                    object-position: -206px;
                }
                .fakeButton{
                    bottom: 255px;
                    left: 250px;
                }
            }
            &.vip{
                .fakeButton{
                    bottom: 250px;
                }
                height: 908px;
                .button_0{
                    left: 112px;
                }
                .button_1{
                    left: 443px;
                }
                .button_2{
                    right: 136px;
                }
            }
			.vipSwiperBox {
				position: relative;
				.touchableButton{
					position: absolute;
					width: 90px;
					height: 28px;
					opacity: 0;
					z-index: 100;
					border-radius: 20px;
					bottom: 250px;
					left: 50%;
					transform: translateX(-50%);
				}
				&.generallevelInfoImage{
					position: relative;
					width: 315px;
					height:628px;
				}
				&.starlevelInfoImage{
					position: relative;
					width: 315px;
					height:900px;
				}
				&.viplevelInfoImage{
					position: relative;
					width: 315px;
					height:908px;
				}
			}
		}
	}
    .collapse-box {
        display: flex;
        column-gap: 52px;
    }
    .table_container{
        background-color: #2D2F33;
        color: #ABA79D;
        tr{
            border-bottom: 1px solid #393b40;
            &:last-child{
                border-bottom: unset;
            }
            th{
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                height: 70px;
                width: 250px;
                &:first-child{
                    text-align: left;
                    padding-left: 40px;
                }
                &:nth-last-child(-n+2){
                    color: #C4B590;
                }
            }
            td{
                height: 70px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                &:first-child{
                    text-align: left;
                    padding-left: 40px;
                }
                &:nth-last-child(-n+2){
                    color: #C4B590;
                }
            }
        }
        &.prizes{
            tr{
                &:nth-last-child(-n+3){
                    td{
                        color: #ABA79D;
                    }
                }
                th{
                    &:nth-child(4){
                        color: #C4B590;
                    }
                    &:nth-last-child(-n+5){
                        width: 150px;
                    }
                }
            }
        }
        &.otherBenefit{
            tr{
                &:nth-last-child(3){
                    td{
                        &:nth-last-child(2){
                            color: #ABA79D;
                        }
                    }
                }
            }
        }
        &.expiration{
            tr{
                th:nth-child(1){
                    height: 91px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
                th:nth-child(2){
                    color: #ABA79D;
                    width: 160px;
                    height: 91px;
                }
                th:last-child{
                    width: 635px;
                    height: 39px;
                }
                td:nth-last-child(2){
                    color: #ABA79D;
                }
                &:nth-last-child(2){
                    td{
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        height: 52px;
                    }
                }
                &:last-child{
                    td{
                        height: 52px;
                    }
                }
            }
        }
    }
    #DiamondClub{
        padding-bottom: 80px;
        .common-distance{
            width: 1000px;
            padding: unset;
        }
        #mainTab{
            > .tabLists{
                gap:36px;
                .tabPane_titleBox {
                    padding: 0 16px;
                    .tabPane_title{
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 27.2px;
                        letter-spacing: -0.005em;
                        color:#999999;
                    }
                    &.active{
                        .tabPane_title{
                            color: #C5AF5F;
                        }
                    }
                    ~ .tabInk{
                       height: 6px;
                       background-color: #C5AF5F !important;
                       ~ .buttomLine{
                        background-color: #999999;
                       }
                   }
                }
            }
            #subTab{
                margin-top: 41px;
                > .tabLists{
                    margin-bottom: 50px;
                }
            }

        }
    }
    .detailContent{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .TopBanner{
            height: 228px;
            border-radius: 12px;
            overflow: hidden;
            img{
                top:unset;
            }
        }
    }
    .rc-collapse {
        background-color: #17191c;
        border: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .rc-collapse-item {
            background-color: #2d2f33;
            padding: 0px 15px;
            border-radius: 10px;
            width: 100%;
            height: fit-content;
            min-height: 44px;
            .rc-collapse-header {
                color: #d6d1c2;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                cursor: default;
                display: flex;
                align-items: center;
                height: 44px;
                flex-direction: row-reverse;
                justify-content: space-between;
                .rc-collapse-expand-icon {
                    cursor: pointer;
                    svg {
                        color: #fff;
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .rc-collapse-content-box {
                table {
                    width: fit-content !important;
                    max-width: 100%;
                }

                .FaqContent {
                    background-color: #2d2f33;
                    padding: 0 24px 14px 0px;
                    color: #d6d1c2;
                    font-weight: 300;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    * {
                        max-width: 100%;
                    }
                    .link{
                        color:#D6D1C2;
                    }
                }
            }
        }
        &.levelFaq{
            flex-direction: row;
            flex-wrap: wrap;
            column-gap:40px;
            .rc-collapse-item{
                flex-basis: 480px;
                min-height: 44px;
                padding: 0 15px;
            }    
        }
    }
    .levelTextContent{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .levelFaq_box{
            .faqLists_box{
                display: flex;
                gap:40px;
            }
        }
    }
   

    // Top tab
    .diamond-club-tob-tab {
        .ant-tabs-nav-container {
            .ant-tabs-nav-wrap {
                .ant-tabs-nav-scroll {
                    border-radius: 0px;
                    border-bottom: 1px solid #e0e0e0;

                    .ant-tabs-nav {
                        position: initial;

                        .ant-tabs-tab:hover {
                            color: #c7c7c7;
                        }

                        .ant-tabs-tab-active {
                            color: #c5af5f !important;
                        }
                    }
                }
            }
        }
    }

    .ant-tabs-bar {
        margin-bottom: 24px;
    }
    //general setting
    .ant-tabs.border-tabs {
        .ant-tabs-nav-container::after {
            display: block;
            height: 2px;
            background: white;
        }

        .ant-tabs-tab-active {
            color: #c5af5f !important;
            background-color: transparent !important;
        }
        .ant-tabs-ink-bar {
            bottom: 0;
            background-color: #c5af5f;
        }
    }
    caption,
    .FaqTitle,
    h3 {
        color: #c5af5f;
        caption-side: top;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .menu-section.app-down {
        h3 {
            padding: 0;
        }
    }
    .ant-tabs {
        max-width: 1000px;
        margin: 0 auto;
    }
}
