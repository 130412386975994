.left-next,
.right-next {
    top: 0;
    bottom: 0;
    width: 36px;
    height: 100px;
    margin: auto;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    //background-color: #676767;
    z-index: 1;
    img {
        width: 32px;
        height: 64px;
    }
}

.left-next {
    left: 22px;
}

.right-next {
    right: 22px;
}

.crypto-lesson-wrap {
    position: relative;
    width: 100%;
    height: 524px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    &.huobi-ls-1 {
        background-image: url(/th/img/usdt/learn/huobi_1.png);
    }
    &.huobi-ls-2 {
        background-image: url(/th/img/usdt/learn/huobi_2.png);
    }
    &.huobi-ls-3 {
        background-image: url(/th/img/usdt/learn/huobi_3.png);
    }
    &.huobi-ls-4 {
        background-image: url(/th/img/usdt/learn/huobi_4.png);
    }
    &.huobi-ls-5 {
        background-image: url(/th/img/usdt/learn/huobi_5.png);
    }
    &.bian-ls-1 {
        background-image: url(/th/img/usdt/learn/bian_1.png);
    }
    &.bian-ls-2 {
        background-image: url(/th/img/usdt/learn/bian_2.png);
    }
    &.bian-ls-3 {
        background-image: url(/th/img/usdt/learn/bian_3.png);
    }
    &.bian-ls-4 {
        background-image: url(/th/img/usdt/learn/bian_4.png);
    }
    &.bian-ls-5 {
        background-image: url(/th/img/usdt/learn/bian_5.png);
    }
    &.bian-ls-6 {
        background-image: url(/th/img/usdt/learn/bian_6.png);
    }
    &.okex-ls-1 {
        background-image: url(/th/img/usdt/learn/okex_1.png);
    }
    &.okex-ls-2 {
        background-image: url(/th/img/usdt/learn/okex_2.png);
    }
    &.okex-ls-3 {
        background-image: url(/th/img/usdt/learn/okex_3.png);
    }
    &.okex-ls-4 {
        background-image: url(/th/img/usdt/learn/okex_4.png);
    }
    &.im-ls-1 {
        background-image: url(/th/img/usdt/learn/im_1.png);
    }
    &.im-ls-2 {
        background-image: url(/th/img/usdt/learn/im_2.png);
    }

    &.rapid-ls-1 {
        background-image: url(/th/img/depositlesson/DepositCryptoChannelFlow_1.jpg);
    }
    &.rapid-ls-2 {
        background-image: url(/th/img/depositlesson/DepositCryptoChannelFlow_2.jpg);
    }
    &.rapid-ls-3 {
        background-image: url(/th/img/depositlesson/DepositCryptoChannelFlow_3.jpg);
    }
    &.rapid-ls-4 {
        background-image: url(/th/img/depositlesson/DepositCryptoChannelFlow_4.jpg);
    }
    &.rapid-ls-5 {
        background-image: url(/th/img/depositlesson/Crypto.jpg);
    }

    &.otc-ls-1 {
        background-image: url(/th/img/depositlesson/DepositCryptoInvoiceFlow_1.jpg);
    }
    &.otc-ls-2 {
        background-image: url(/th/img/depositlesson/DepositCryptoInvoiceFlow_2.jpg);
    }
    &.otc-ls-3 {
        background-image: url(/th/img/depositlesson/DepositCryptoInvoiceFlow_3.jpg);
    }
    &.otc-ls-4 {
        background-image: url(/th/img/depositlesson/Crypto.jpg);
    }
}

.crypto-lesson-body {
    margin-left: 6rem;
    margin-right: 6rem;
}

.deposit-comment-title {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 16px;
    margin-top: 40px;
    color: #222222;
}

.deposit-comment-content {
    text-align: left;
    font-size: 14px;
    color: #222222;
    min-height: 54px;
}

.custom-Carousel {
    .slick-dots {
        bottom: -18px !important;
        z-index: 999;
        button {
            margin-right: 5px;
        }
    }
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active button {
    background: #000000bf;
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.ant-carousel .custom-Carousel .slick-dots li {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li button {
    background: #00000040;
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.arrowLeftRight {
    background-color: #00000040;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.comment-title {
    text-align: center;
    color: #f92d2d;
    font-size: 18px;
    font-weight: 1000;
    padding-top: 2rem;
}

.comment-content {
    text-align: left;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 10rem;
    margin-right: 10rem;
    line-height: 20px;
    text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}

.deposit-comment-button {
    width: 334px;
    margin-top: 20px;
}

.titleHeader {
    background-color: #00a6ff;
    color: white;
}
