.promotion-tabs {
    .ant-radio-group {
        display: flex;
    }
}

.promotion-deposit-wrap {
    padding: 70px 10px;
    padding-top: 40px;
}

.promotion-deposit-wrap > .ant-col:first-child {
    text-align: center;
    margin-top: 40px;
}

.promotion-deposit-wrap .slider-title {
    margin-top: 16px;
    margin-bottom: 20px;
}

.promotion-deposit-wrap .ant-input-number {
    width: 100%;
}

.promotion-deposit-wrap .ant-input-number-handler-wrap {
    display: none;
}

.promotion-deposit-wrap .ant-select {
    display: block;
}

.promotion-modal .ant-slider-handle {
    background-color: @theme;
}

.step-line-row {
    margin-left: 10px;
    margin-right: 20px;
}

.step-line-row .ant-col {
    height: 3px;
    border-left: 1px solid #bfbfbf;
}

.step-line-row .ant-col:last-child {
    border-right: 1px solid #bfbfbf;
}

.step-percentage-row .ant-col:nth-child(1) {
    text-align: left;
}

.step-percentage-row .ant-col:nth-child(2) {
    text-align: center;
}

.step-percentage-row .ant-col:nth-child(3) {
    text-align: right;
}

.tlc-promotions-wrap {
    margin-top: -55px !important;
}

.tlc-promotions-wrap .ant-tabs-bar.ant-tabs-left-bar {
    margin-top: 60px;
}

.tlc-promotions-wrap.ant-tabs {
    overflow: visible;
    padding-bottom: 30px;
}

.tlc-promotions-wrap.ant-tabs .ant-tabs-left-content {
    border-left: 0;
}

.transfer-money-wrap {
    position: relative;
    border-radius: 5px;
    border: 1px solid #bfbfbf;
    padding: 20px;
}

// 优惠Confirm样式表
.pormotion-confirm {
    .svg {
        color: @theme;
        font-size: 66px;
        margin-bottom: 0;
    }

    .ant-modal-close {
        display: none;
    }

    .ant-modal-confirm-body .ant-modal-confirm-content {
        margin-top: 0;
    }

    &.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
        border-radius: 22.5px;
    }
}

.loading_card_list {
    display: flex;
    flex-flow: wrap;

    li {
        width: 33.333%;
        height: 230px;
        border-radius: 20px;
        margin-bottom: 20px;

        .box {
            border: 1px solid #ddd;
            height: 100%;
            margin: 10px;
            border-radius: 20px;
            overflow: hidden;
            &:extend(.animationcard);
        }
    }
}

.animationcard {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(25%, #f2f2f2),
        color-stop(37%, #e6e6e6),
        color-stop(63%, #f2f2f2)
    );
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
}

@-webkit-keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@keyframes ant-skeleton-loading {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

.promotions-content {
    //上方Tab選單
    .ant-tabs-top-bar {
        margin-bottom: 30px;

        .ant-tabs-nav-wrap {
            display: unset;
        }
    }

    //優惠Tab && 返水左側選單
    .tlc-promotions-sidebar .ant-tabs-left-bar,
    .rebate-leftside-tab .ant-tabs-left-bar {
        width: 200px;
        background-color: white;
        text-align: center;

        .ant-tabs-nav-scroll {
            width: 100%;
        }
        //優惠Tab
        .ant-tabs-nav-wrap {
            display: unset;
        }

        //包裹items container
        .ant-tabs-nav > div {
            display: flex;
            flex-direction: column;

            //每個item
            .ant-tabs-tab {
                padding: 10px 0 10px 37px;
                font: normal normal 14px/19px "Noto Sans Thai", Arial, san-serif;
                .icon-list p {
                    max-width: 100px;
                    white-space: pre-wrap;
                    text-align: left;
                }
            }

            //被點擊的item
            .ant-tabs-tab-active {
                font: normal normal bold 14px/19px "Noto Sans Thai", Arial, san-serif;
                background-color: #e0e0e0;
                color: #000;
            }
        }

        //tab indicator
        .ant-tabs-ink-bar {
            width: 6px;
        }
    }

    .ant-tabs-left-bar {
        background-color: white;
        border-radius: 10px;
        padding: 15px 0 0 0;
    }

    .ant-tabs-ink-bar {
        left: 0 !important;
        // width: 4px !important;
    }

    //我的優惠Tab > 免費投注Tab
    .PromotionsFreeBet {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 30px;
        column-gap: 30px;
        row-gap: 32px;

        .FreeBet-item {
            width: 100%;
            height: 154px;
            border-radius: 8px;
            background: white;
            -webkit-mask-image: radial-gradient(
                    circle at 15px 50%,
                    transparent 15px,
                    white 15.5px
                ),
                radial-gradient(
                    closest-side circle at 50%,
                    white 99%,
                    transparent 100%
                );
            -webkit-mask-size: 100%, 12px 4px;
            -webkit-mask-repeat: repeat, repeat-x;
            -webkit-mask-position: -15px, 50% 50%;
            -webkit-mask-composite: source-out;
            mask-composite: subtract;
            position: relative;
            .Content {
                display: flex;
                flex-direction: column;
                height: 100%;
                gap: 34px;
                padding: 24px 32px;
                .content {
                    display: flex;
                    flex-direction: column;

                    .Name {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        line-height: 20px;
                    }
                    .Time {
                        color: #999999;
                    }
                }
                .btn-apply-freebet {
                    width: 38.187%;
                }
            }

            .Freebet-popover {
                cursor: pointer;
            }
        }
    }
}

//General Modal Style
.promotion-modal {
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
        .ant-modal-header {
            .ant-modal-title {
                text-align: center;
                color: #ffffff;
                font-weight: 500;
                font-size: 20px;
            }
        }
        .ant-modal-body {
            padding: 30px;
            display: flex;
            flex-direction: column;

            .ant-spin-container {
                padding: 0;
            }

            // 優惠投注方框
            .promotion-info-box {
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                padding: 20px 0;
                margin-bottom: 16px;

                h3 {
                    text-align: center;
                    font: normal normal bold 14px/19px Microsoft YaHei;
                    margin-bottom: 20px;
                }
                .amount-status-box {
                    display: flex;

                    // 各金額方框
                    div {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .amount {
                            font: normal normal bold 14px/19px Microsoft YaHei;
                            color: #222222;
                        }
                    }
                }
            }
            textarea {
                resize: none;

                &::-webkit-resizer {
                    display: none;
                }
            }
        }
        .ant-modal-footer {
            padding: 0 30px 30px 30px;
            .ant-btn-primary {
                margin: 0;
                width: 100%;
                font: normal normal normal 14px/19px "Noto Sans Thai", Arial, san-serif;
            }
        }
    }
}

//客製優惠詳情modal
.promotion-detail-modal {
    width: 1120px;

    .ant-modal-header {
        padding: 0px;
        background-color: #00a6ff;

        .ant-modal-title {
            font: normal normal bold 18px/24px "Noto Sans Thai", Arial, san-serif;
            padding: 18px 0;
            color: #fff;
            text-align: center;
        }
    }

    .ant-modal-body {
        padding: 30px 50px;

        .ant-spin-container {
            padding: 0;

            .apply-promotion-detail-box {
                margin-bottom: 30px;
                border: none;
                padding-bottom: 30px;
            }
        }
    }

    .empty-state {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 500px;
        color: #999999;
        font-size: 16px;
        span {
            color: #00A6FF;
            cursor: pointer;
        }
    }

    .button-box {
        display: flex;
        justify-content: center;
        position: relative;

        button {
            width: 30.8%;
            border: none;
            font: normal normal normal 16px/22px "Noto Sans Thai", Arial, san-serif;
            color: #fff;
            background-color: #00a6ff;
            padding: 12px 0;
            border-radius: 6px;
            cursor: pointer;
        }

        button:disabled {
            color: #999999;
            background-color: #e3e3e3;
            cursor: not-allowed;
        }

        .dialogOfBonusAmount {
			background-color: #222;
			border-radius: 10px 10px 10px 0px;
			padding: 8px 12px;
			height: 36px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			position: absolute;
			top: -26px;
			left: 50%;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			color: #FFF;
			.amount {
				color: #00A6ff;
			}
		}
    }
}

// 客製已提交資料查看Modal
.check-apply-data-container {
    .ant-modal-body {
        color: #222222;
    }
    &.physical {
        h3 {
            font-size: 16px;
            margin-bottom: 24px;
        }
        label {
            margin-bottom: 8px;
        }
        .info {
            margin-bottom: 24px;
            background-color: #efeff4;
            border-radius: 8px;
            padding: 16px;
            .name {
                font-size: 16px;
                font-weight: 500;
            }
            .phone {
                color: #999999;
                margin-bottom: 16px;
            }
            .address {
                color: #666666;
                font-weight: 500;
            }
        }
        .remark {
            background-color: #efeff4;
            border-radius: 8px;
            padding: 12px;
            min-height: 88px;
        }
    }
    &.non-physical {
        h3 {
            font: normal normal 500 16px/18px "Noto Sans Thai", Arial, san-serif;
            margin-bottom: 30px;
        }
        .user-info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            //每組label+input
            .ant-input-group-wrapper {
                & + p {
                    padding: 3px 0 0 11px;
                    margin: 0;
                    font-size: 12px;
                    color: #666666;
                    span {
                        color: #00a6ff;
                        cursor: pointer;
                    }
                }
                // 手動作成label
                label {
                    display: block;
                    padding-left: 11px;
                    margin-bottom: 8px;
                    color: #222222;
                }
                textarea {
                    max-height: 80px;
                    padding: 17px 20px;
                    color: #999999;
                    background-color: #f4f2f2;
                }
                .ant-input-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    //自動生成label created-label
                    .ant-input-group-addon {
                        background-color: transparent;
                        border: none;
                        color: #222222;
                    }
                    input {
                        display: block;
                        background: #f4f2f2 0% 0% no-repeat padding-box;
                        border: 1px solid #cccccc;
                        border-radius: 8px;
                        color: #999999;
                        padding: 20px 13px;
                    }
                }
            }
        }
    }
}

// 客製 Manual 申請 Modal
.manual-apply-modal {
    .Formbox {
        h3 {
            font: normal normal 500 16px/18px "Noto Sans Thai", Arial, san-serif;
            letter-spacing: 0px;
            color: #222222;
            text-align: left;
            margin-bottom: 16px;
        }
        .ant-form-extra {
            font-size: 12px;
            padding-top: 6px;
            p {
                color: #666666;
                span {
                    color: #00a6ff;
                    cursor: pointer;
                }
            }
            .remark {
                text-align: right;
            }
        }
        .remarks-item {
            margin-bottom: 32px;
            .ant-form-extra {
                padding: 0px;
                margin: 0px;
                min-height: fit-content;
            }
            textarea {
                height: 72px;
                resize: none;
                padding: 12px 16px;
            }  
        }
    }
}

// 客製 Reload Bonus 申請 Modal
.bonus-applied-modal {
    .bonus-applied-title {
        font-weight: 600;
        font-size: 16px;
        color: #222;
        margin-bottom: 16px;
    }
    .ant-form-item:last-of-type {
        margin-bottom: 32px;
    }
}

// 客製Free Promotion溫馨提醒Modal
.warm-reminder-modal {
    span {
        margin-bottom: 40px;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-footer {
        > div {
            display: flex;
            width: 100%;
            gap: 20px;

            button {
                width: 100%;
                height: 40px;
                border-radius: 8px;
                padding: 10px 0;
                cursor: pointer;

                &:nth-child(1) {
                    background-color: transparent;
                    border: 1px solid #00a6ff;
                    letter-spacing: 0px;
                    color: #00a6ff;
                }

                &:nth-child(2) {
                    background: #00a6ff 0% 0% no-repeat padding-box;
                    color: #fff;
                    border: 1px solid #fff;
                }
                span {
                    display: block;
                    margin: 0;
                }
            }
        }
    }
}
// 客製返水自訂義日期Modal
.rebate-time-picker-modal {
    .ant-modal-content {
        border-radius: 20px;
        overflow: hidden;
        .ant-modal-body {
            padding: 0px 20px 24px;
            // 被選定日期樣式
            .ant-calendar-selected-day,
            .ant-calendar-selected-date {
                .ant-calendar-date {
                    background-color: transparent;
                    &.selected-day {
                        background-color: #00a6ff;
                        color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
        > button {
            margin-top: 10px;
            svg {
                color: black;
                // padding: 10px 0;
            }
        }
        .ant-modal-header {
            background-color: transparent;
            padding-bottom: 20px;
            .ant-modal-title {
                color: #222;
                padding: 10px 0;
            }
        }
    }
}

.icon-list {
    display: flex;
    // margin-left: 10%;

    .icon-image {
        filter: grayscale(1);
        padding-right: 10px;
        height: 20px;
        vertical-align: -0.4em;
    }
    .icon-image-active {
        filter: unset;
    }
}

.htmlbox {
    padding-bottom: 100px;
}

//優惠篩選右側結果區塊
.promotion-list-box {
    .Pms_data_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        column-gap: 16px;
        row-gap: 16px;
        margin-bottom: 30px;
        min-height: 629px;
        position: relative;
        .item-list {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 2px 4px #00000014;

            .image-wrapper {
                position: relative;
                width: 100%;
                height: 108px;
            }
        
            .image-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.15);
                z-index: 1;
            }
        }
        .promotion-item-box {
            width: 100%;
            height: 100%;
            cursor: pointer;
            //border-radius: 20px;
            overflow: hidden;
            //box-shadow: 0px 2px 4px #00000014;

            .content-box {
                height: 100%;
                display: grid;
                grid-template-rows: auto 1fr;

                .promotion-image {
                    width: 100%;
                    height: fit-content;
                    aspect-ratio: 5.714/2;
                    background-color: #ddd;
                }

                .text-box {
                    background-color: white;
                    padding: 10px 8px 10px 10px;
                    cursor: pointer;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    .description {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0 0 8px 0;
                        min-height: 40px;
                        .title {
                            color: #222222;
                            font: normal normal bold 14px/20px "Noto Sans Thai", Arial, san-serif;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* 指定显示的行数 */
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 162px;
                        }
                        .statusTag {
                            font-size: 12px;
                            border-radius: 5px;
                            color: #ffffff;
                            background-color: #00A6FF;
                            padding: 4px 8px;
                            &.disabled {
                                color: #999999;
                                background-color: #e3e3e3;
                            }
                            &.arrow {
                                color: #00A6FF;
                                background-color: transparent;
                                padding: 0;
                                &::after {
                                   content: "";
                                   display: inline-block;
                                   transform: rotate(-90deg);
                                   width: 18px;
                                   height: 8px;
                                   background-image: url(/th/img/home/sprite20230330.png);
                                   background-repeat: no-repeat;
                                   background-position: -7px -62px;
                                }
                            }
                        }
                    }
                    .period {
                        display: flex;
                        align-items: center;
                        font: normal normal normal 10px/16px "Noto Sans Thai", Arial, san-serif;
                        color: #999999;
                        p {
                            margin: 0;
                            letter-spacing: -0.3px;
                        }
                        span {
                            margin-right: 5px;
                            width: 14px;
                            height: 18px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        //當該分類內無優惠時的Empty style setting
        .ant-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 85%;

            .ant-empty-image {
                width: 13.637%;
                height: max-content;
                margin: 0;
            }
            .ant-empty-description {
                white-space: nowrap;
                font: normal normal normal 18px/24px "Noto Sans Thai", Arial, san-serif;
                letter-spacing: 0px;
                color: #999999;
            }
        }
    }
}

.promotions-banner {
    height: 160px;
    text-align: center;
    max-width: 1120px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 0 -35px;
    }
}

.promotions-content {
    padding-top: 20px;
    min-height: 900px;
}

/*2023.01.17 add*/

.promotionCard {
    overflow: auto;
    .Card {
        height: 160px;
        width: 327px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        display: inline-block;
        margin-right: 10px;
        vertical-align: top;
        overflow: hidden;
        img {
            width: 100%;
            height: 114px;
            border-radius: 10px 10px 0px 0px;
        }
    }
}
.blue {
    color: #00a6ff;
}

/*Rebate*/

.total_reabate {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 3% 0;
    span {
        font-size: large;
        padding: 0 5%;
    }
}

//返水內容
.promotion-modal .ant-spin-container {
    padding: 3% 5%;
}
.content_list {
    background-color: #e0e0e0;

    border-radius: 20px;
    h3 {
        padding-left: 5%;
    }
}

.rebate-container {
    .rebate-content {
        min-height: 400px;
    }
    .filter-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .interval-selector-box {
            display: flex;
            justify-content: flex-end;
            overflow: hidden;

            .ant-radio-button-wrapper {
                height: 32px;
                text-align: center;
                font: normal normal normal 14px/32px Noto Sans Thai;
                background-color: transparent;
            }

            .ant-radio-button-wrapper-checked,
            .ant-radio-button-wrapper-checked:hover {
                background-color: #00a6ff !important;
                border: none;
                box-shadow: none !important;
            }

            // .ant-radio-button-wrapper:hover {
            //   color: #222222;
            // }

            // .ant-radio-button-wrapper-checked:hover {
            //   color: #ffffff;
            // }
        }
    }

    // 返水清單 container
    .Pms_data_rebate {
        .defined-date-description {
            width: 100%;
            text-align: right;
            margin-top: 15px;

            span {
                color: #00a6ff;
                cursor: pointer;
            }
        }

        .rebate-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(5, 1fr);
            gap: 30px;
            position: relative;
            margin-bottom: 30px;
            min-height: 629px;
            position: relative;

            .rebate-item-box {
                background-color: #ffffff;
                border: 1px solid #e0e0e0;
                border-radius: 10px;
                padding: 15px 20px;

                .header-box {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #e0e0e0;

                    .profile {
                        display: flex;

                        .icon-image {
                            width: 48px;
                            height: 48px;
                        }

                        .text-box {
                            display: flex;
                            gap: 16px;
                            align-items: center;

                            h3 {
                                color: #222222;
                                font-weight: 500;
                                margin: 0;
                                font-size: 14px;
                            }

                            p {
                                margin: 0;
                            }
                        }
                    }

                    button {
                        cursor: pointer;
                        background-color: transparent;
                        border: none;
                    }
                }

                .rebate-info-box {
                    padding-top: 15px;
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    text-align: center;

                    .content_item {
                        p {
                            color: #222222;
                        }

                        .title {
                            color: #999999;
                        }

                        .total-amount {
                            color: #42d200;
                        }
                    }
                }
            }

            //當該分類內無優惠時的Empty style setting
            .ant-empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                position: absolute;
                width: 100%;
                height: 70%;
                .ant-empty-image {
                    width: 160px;
                    height: max-content;
                    margin: 0;

                    img {
                        width: 100%;
                    }
                }
                .ant-empty-description {
                    white-space: nowrap;
                    font: normal normal normal 18px/24px Noto Sans Thai;
                    letter-spacing: 0px;
                    color: #999999;
                }
            }
        }
    }
}

// 返水詳細清單(Table)
.table-rebate-detail {
    // Table Style
    .ant-table-content {
        .ant-table-thead {
            tr {
                th {
                    color: black;
                    text-align: center;
                    background-color: transparent;
                }
            }
        }
        .ant-table-tbody {
            tr {
                text-align: center;
            }
        }
        .ant-table-placeholder {
            .ant-empty-description {
                color: #c7c7c7;
            }
        }
    }

    // Pagination Style

    .ant-pagination {
        width: 100% !important;
        margin: 30px 0 0 0 !important;

        li {
            a {
                color: #222222;
            }
        }

        // Current page style
        .ant-pagination-item-active {
            border-color: transparent;
            background-color: #f0f0f2;
        }
    }
}

// 轉帳錢包選擇
.wallet-list-box {
    .option-item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        p {
            margin: 0;
        }

        .button-transfer {
            background-color: transparent;
            border: none;
            display: none;
        }
    }
}
