.left-next,
.right-next {
    top: 0;
    bottom: 0;
    width: 36px;
    height: 64px;
    margin: auto;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // background-color: #676767;
    z-index: 1;
    img {
        width: 32px;
        height: 64px;
    }
}

.left-next {
    left: -1px;
}

.right-next {
    right: -1px;
}

.crypto-lesson-wrap {
    position: relative;
    width: 100%;
    height: 323px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    &.withdraw-ls-1 {
        background-image: url(/th/img/withdrawlesson/withdrawal_1.jpg);
    }
    &.withdraw-ls-2 {
        background-image: url(/th/img/withdrawlesson/withdrawal_2.jpg);
    }
    &.withdraw-ls-3 {
        background-image: url(/th/img/withdrawlesson/withdrawal_3.jpg);
    }
    &.withdraw-ls-4 {
        background-image: url(/th/img/withdrawlesson/withdrawal_4.jpg);
    }

    &.address-ls-1 {
        background-image: url(/th/img/withdrawlesson/address_1.jpg);
    }
    &.address-ls-2 {
        background-image: url(/th/img/withdrawlesson/address_2.jpg);
    }
    &.address-ls-3 {
        background-image: url(/th/img/withdrawlesson/address_3.jpg);
    }
    &.address-ls-4 {
        background-image: url(/th/img/withdrawlesson/address_4.jpg);
    }
}

.crypto-lesson-body {
    margin-left: 6rem;
    margin-right: 6rem;
}

.withdraw-comment-title {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 16px;
    margin-top: 40px;
    color: #222222;
}

.withdraw-comment-content {
    text-align: left;
    font-size: 14px;
    color: #222222;
    min-height: 54px;
}

.slick-dots .custom-Carousel {
    bottom: -5px !important;
    z-index: 999;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active button {
    background: #00a6ff;
    border-radius: 8px;
    height: 4px;
    width: 12px;
}

.ant-carousel .custom-Carousel .slick-dots li {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li button {
    background: #999999;
    border-radius: 50%;
    height: 4px;
    width: 4px;
}

.arrowLeftRight {
    background-color: #00000040;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.comment-title {
    text-align: center;
    color: #f92d2d;
    font-size: 18px;
    font-weight: 1000;
    padding-top: 2rem;
}

.comment-content {
    text-align: left;
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 10rem;
    margin-right: 10rem;
    line-height: 20px;
    text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}

.withdraw-comment-button {
    width: 334px;
    margin-top: 20px;
}

.withdraw-lesson {
    .ant-modal-content {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .ant-modal-header {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }
}