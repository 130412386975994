/*
  From M2 Mobile /component/Common/Table
  Tabs | TabPane組件 Default style for Version 1.0.1
*/

.table_container{
  font-size: 10px;
  font-weight: 400;
  line-height: 15.11px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  tr{
    border-bottom: 1px solid #393b40;
    &:last-child{
      border-bottom: unset;
    }
    th{
      text-align: center;
      vertical-align: middle;
      white-space: pre-line;
      border-right: 1px solid #393b40;
      &:last-child{
        border-right: unset;
      }
    }
    td{
      vertical-align: middle;
      text-align: center;
      white-space: pre-line;
      border-right: 1px solid #393b40;
      &:last-child{
        border-right: unset;
      }
    }
  }
}