//安全屋，访问限制，维护，相关
.BoxOther {
    padding: 0 40px;
    margin: 80px auto;
    height: 480px;
    width: 960px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    .Box_Content {
        width: 360px;
        background-color: white;
        height: auto;
        margin-top: 65px;
        float: right;
        border-radius: 20px;
        overflow: hidden;
        color: white;
        padding-bottom: 20px;
        h3 {
            color: white;
            background-color: #00a5fd;
            text-align: center;
            padding: 10px;
            margin-bottom: 0;
        }
        .ant-form {
            padding: 0px 20px;
            button {
                width: 100%;
            }
            .ant-form-item-label {
                width: 100%;
                text-align: left;
            }
        }
    }
    .ErrorShow {
        background-color: #fee5e5;
        color: #eb2121;
        padding: 10px;
        margin: 10px 10px 0px 10px;
        border-radius: 10px;
        text-align: center;
    }
}
.sf {
    background-image: url("/th/img/other/sf.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
#maintain {
    background-image: url("/th/img/other/Bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.checkbox-button-container {
    span {
        font-size: 12px;
    }
    .ant-checkbox-inner {
        width: 18px;
        height: 18px;
        border-radius: 4px;
    }
}
