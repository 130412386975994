.locked-balance-modal {
    .ant-modal-content {
        overflow: hidden;
    }
    .ant-modal-header {
        background-color: #efeff4;
        padding: 20px 28px;
        .ant-modal-title {
            .modal-title {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .modal-description {
                font-size: 12px;
                color: #999999;
                margin: 8px 0;
                text-align: left;
                .modal-description-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #666666;
                    margin-right: 8px;
                }
            }

            .modal-amount {
                font-size: 20px;
                font-weight: 600;
                color: #00a6ff;
                text-align: left;
            }
        }
    }
    .ant-modal-body {
        background-color: #efeff4;
        padding: 0 28px 40px;
    }
}
.locked-balance-container {
    .locked-details {
        margin-bottom: 20px;
        padding: 16px;
        background-color: #ffffff;

        .locked-description {
            font-size: 12px;
            color: #999999;
            margin-bottom: 8px;
            .locked-description-title {
                font-size: 14px;
                font-weight: 600;
                color: #666666;
                margin-right: 8px;
            }
        }

        .locked-amount {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
            display: flex;
            gap: 4px;
            align-items: center;
            .lock {
                width: 17px;
                height: 17px;
            }
        }

        .locked-update-time {
            font-size: 10px;
            color: #999999;
        }
    }
    .locked-details-table {
        background-color: #ffffff;
        padding: 16px;
        .bonus-locked {
            display: flex;
            gap: 4px;
            align-items: center;
            margin-bottom: 20px;
            .bonus-title {
                font-size: 14px;
                font-weight: 600;
                color: #666666;
                margin: 0 4px 0 0;
            }
            .lock {
                width: 17px;
                height: 17px;
            }
            .bonus-amount {
                font-size: 16px;
                font-weight: 600;
            }
        }
        .bonuses-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;

            .table-header {
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                color: #666666;
                background-color: #efeff4;
                padding: 8px;
            }

            .gmt-label {
                font-weight: 400;
            }

            .table-row {
                border-bottom: 1px solid #e3e3e3;
                &:last-child {
                    border-bottom: none; /* 移除最後一行的下底線 */
                }
            }

            .table-cell {
                font-size: 12px;
                color: #666666;
                padding: 8px;

                &.no-border {
                    border: none;
                }
            }

            /* 調整每列的寬度 */
            th:nth-child(1),
            td:nth-child(1) {
                width: 100px; /* หมวดหมู่เกม */
            }

            th:nth-child(2),
            td:nth-child(2) {
                width: 140px; /* ชื่อโบนัส */
            }

            th:nth-child(3),
            td:nth-child(3) {
                width: 100px; /* เวลาปรับยอด */
            }

            th:nth-child(4),
            td:nth-child(4) {
                width: 120px; /* ยอดโบนัส */
            }

            th:nth-child(5),
            td:nth-child(5) {
                width: 120px; /* ยอดหมุนเวียนที่ยังไม่ครบ */
            }

            th:nth-child(6),
            td:nth-child(6) {
                width: 230px; /* ความคืบหน้า */
            }

            td:nth-child(4),
            td:nth-child(5) {
                text-align: center;
            }

            .bonus-name {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -Webkit-line-clamp: 2;
                -Webkit-box-orient: vertical;
            }

            .date-time-container {
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }

        .progress-wrapper {
            .progress-description {
                .progress-note {
                    font-size: 10px;
                    color: #999999;
                }
            }

            .progress-text {
                display: flex;
                align-items: center;
                margin: 4px 0;
                font-size: 12px;

                .progress-completed {
                    color: #00a6ff;
                    font-weight: 600;
                    margin-right: 4px;
                }

                .progress-separator {
                    color: #999;
                    margin-right: 4px;
                }

                .progress-total {
                    color: #999;
                }

                .progress-percentage {
                    margin-left: auto;
                    color: #a1a1a1;
                    font-weight: 600;
                    text-align: right;
                }
            }

            .progress-bar-background {
                position: relative;
                width: 100%;
                height: 6px;
                background-color: #e3e3e3;
                border-radius: 10px;
                overflow: hidden;

                .progress-bar-fill {
                    height: 100%;
                    border-radius: 4px;
                    position: relative;
                    background-color: #00a6ff;
                }
            }
        }

        .no-records {
            text-align: center;
            font-size: 12px;
            color: #666666;
            padding-top: 20px;
        }
    }
}
