@use autoprefixer;

.sponsor-wrap {
    .ant-col {
        transition: opacity 0.3s;
        cursor: pointer;
        // &::before {
        //   content: "";
        //   width: 15px;
        //   height: 24px;
        //   background-color: @theme;
        //   position: absolute;
        //   right: 80px;
        //   bottom: -8px;
        //   opacity: 0;
        //   transition: opacity .2s;
        // }
    }

    .ant-col:hover {
        opacity: 0.8;

        &::before {
            opacity: 1;
        }
    }

    .sponsor {
        border-radius: 15px;
        overflow: hidden;
    }

    img {
        width: 100%;
    }
}

.ant-tabs.ant-tabs-card.home-promotion-wrap {
    overflow: visible;

    &._2 {
        .ant-tabs-nav-wrap {
            justify-content: flex-start !important;
        }
        .ant-tabs-nav-container {
            height: 120px;

            .ant-tabs-tab {
                height: 120px;
            }
        }
    }

    .ant-tabs-bar {
        height: 100px;
        border-width: 0;
    }

    .ant-tabs-nav-container {
        height: 100px;
        padding: 0;
        font-size: 1.5em;
        overflow: visible;

        .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
        .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
            top: -2.2em;
            height: 40px;

            &.ant-tabs-tab-prev {
                left: 94%;
            }

            .ant-tabs-tab-next-icon,
            .ant-tabs-tab-prev-icon {
                width: 22px;
                height: 22px;
                border: 2px solid #666;
                color: #666;
                border-radius: 50%;
                line-height: 16px;
                font-weight: bold;

                .ant-tabs-tab-prev-icon-target,
                .ant-tabs-tab-next-icon-target {
                    font-size: 14px;
                }
            }

            &:not(.ant-tabs-tab-btn-disabled) {
                .ant-tabs-tab-next-icon:hover,
                .ant-tabs-tab-prev-icon:hover {
                    border-color: #111;
                    color: #111;
                }
            }
        }

        .ant-tabs-tab {
            height: 100px;
            background: transparent;
            border: 0;
            padding: 0;
            transition: none;

            .home-sponsor {
                border-radius: 15px;
                overflow: hidden;
                // box-shadow: 4px 6px 4px #ddd;
                margin-right: 20px;

                img {
                    // 减少1px避免出现左右滚动
                    height: 119px;
                    max-width: 540px;
                }
            }

            &:last-child .home-sponsor,
            &:last-child .home-promotion {
                margin-right: 0;
            }
        }
    }

    .ant-tabs-nav-scroll {
        padding-top: 4px;
    }

    &.sponsor .ant-tabs-bar,
    &.sponsor .ant-tabs-nav-container,
    &.sponsor .ant-tabs-nav-container .ant-tabs-tab {
        height: 120px;
    }
}

.home-split-page-btn-wrap {
    position: absolute;
    width: 99%;
    top: 0;
    height: 30px;

    .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
    .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
        position: absolute;
        top: 0;
        height: 30px;

        &.ant-tabs-tab-prev {
            left: 94%;
        }

        .ant-tabs-tab-next-icon,
        .ant-tabs-tab-prev-icon {
            width: 22px;
            height: 22px;
            border: 2px solid #666;
            color: #666;
            border-radius: 50%;
            line-height: 16px;
            font-weight: bold;

            .ant-tabs-tab-prev-icon-target,
            .ant-tabs-tab-next-icon-target {
                font-size: 14px;
            }
        }

        &:not(.ant-tabs-tab-btn-disabled) {
            .ant-tabs-tab-next-icon:hover,
            .ant-tabs-tab-prev-icon:hover {
                border-color: #111;
                color: #111;
            }
        }
    }
}

.ant-tabs.ant-tabs-line.home-split-page-promotion-wrap {
    padding-top: 4px;
    margin-top: -4px;

    .ant-tabs-bar {
        display: none;
    }

    .ant-tabs-tabpane {
        white-space: nowrap;
    }

    .home-promotion {
        width: 480px;
        display: inline-block;
        border-radius: 50px;
        overflow: hidden;
        // box-shadow: 4px 6px 4px #ddd;
        margin-right: 20px;
        cursor: pointer;
        img {
            height: 100px;
        }
    }
}

/* 健康博彩 从小同开始 */
h1.title {
    margin: 0;
    font-size: 1.8em;
    font-weight: bold;
    line-height: 44px;
    padding-left: 0px;
    color: #222;
}
.cooperate-title {
    font-weight: 500;
    margin-left: 240px;
}
.app-qrcode-wrap {
    background-color: #00a6ff;
    color: #fff;
    height: 260px;
    margin-top: 92px !important;
    margin-bottom: 102px !important;
    font-size: 12px;
    text-align: center;
    .copy-icon {
        padding-left: 10px;
        padding-bottom: 5px;
    }
    .common-distance {
        padding: 0;
    }
    .homeinstallurl {
        cursor: pointer;
        white-space: normal;
        word-break: break-all;
        width: 322px;
        overflow: auto;
    }

    .app-picture {
        width: 100%;
        height: 240px;
        .AppDownloadSection {
            position: absolute;
            top: -78px;
        }
        img {
            margin-top: -18%;
        }
    }

    .qrcode-picture {
        margin-top: 25px;
        height: 175px;
        width: 175px;
        position: relative;
        canvas {
            border: 15px solid #fff;
            margin-left: 2px;
            margin-top: 2px;
            border-radius: 20px;
        }

        & > img {
            width: 166px;
            height: 166px;
            line-height: 166px;
            border: 1px solid #1d1d1d;
        }

        & > p {
            font-size: 12px;
            position: absolute;
            left: 56px;
            bottom: 0;
            margin-bottom: 0.28em;
        }
        .text2 {
            left: 32px;
        }
    }

    .qrcode {
        h1 {
            position: absolute;
            top: -44px;
            left: 0px;
            font-size: 32px;
        }

        .qrcode-article-wrap {
            font-size: 14px;
            line-height: 1.8;
            text-align: left;
            h4 {
                color: #fff;
                margin: 0;
                font-size: 12px;
            }
            .app-down-icons {
                display: block;
                width: 70px;
                height: 30px;
                background-position: -51px -81px;
                background-repeat: no-repeat;
                margin-bottom: 10px;
            }
            .qrcode-article {
                color: #ffffff;
                padding-top: 22px;
                margin-bottom: 14px;
                font-size: 12px;
            }

            & > div {
                color: #ffee00;
                font-size: 16px;
                line-height: 1.2;
                overflow: hidden;
                width: 100%;

                .anticon {
                    margin-left: 6px;
                }
            }
        }
    }
}

// Home Banner
// #t_banner_wrapper {
// 	// &.tlc-banner-list > div > div:nth-child(1n) .tlc-banner-item {
// 	// 	background-color: #feb624;
// 	// }
// 	// &.tlc-banner-list > div > div:nth-child(2n) .tlc-banner-item {
// 	// 	background-color: #6dd400;
// 	// }
// 	// &.tlc-banner-list > div > div:nth-child(3n) .tlc-banner-item {
// 	// 	background-color: #0091ff;
// 	// }
// 	// &.tlc-banner-list {
// 	// 	position: relative;
// 	// 	width: 1120px;
// 	// 	min-width: 1120px;
// 	// 	height: 400px;
// 	// 	line-height: 400px;
// 	// 	top: 0;
// 	// 	left: 0;
// 	// 	overflow: hidden;
// 	// 	.common-distance{
// 	// 		width: 1120px;
// 	// 		margin: auto;
// 	// 		padding:0
// 	// 	}
// 	// }

// 	.tlc-banner-item {
// 		width: 1120px!important;
// 		height: 400px;
// 		line-height: inherit;
// 		text-align: center;
// 		color: #fff;
// 		border-radius: 10px;
// 		overflow: hidden;
// 		box-sizing: border-box;
// 		color: transparent;
// 		overflow: hidden;
// 		background-color: #ebebeb;
// 		margin: auto;
// 		display: block!important;
// 	}

// 	.tlc-banner-item.pointer {
// 		cursor: pointer;
// 	}

// 	.tlc-banner-item img {
// 		height: 100%;
// 		vertical-align: top;
// 		border-radius: 10px;
// 	}

// 	.tlc-carousel-controller {
// 		position: absolute;
// 		height: 0;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		margin: auto;
// 		z-index: 5;
// 	}

// 	.slide-arrow {
// 		width: 40px;
// 		height: 80px;
// 		position: absolute;
// 		top: 152px;
// 		outline: none;
// 		border-width: 0;
// 		border-radius: 4px;
// 		cursor: pointer;
// 		background-color: transparent;
// 		transition: background-color .3s;
// 	}

// 	.slide-arrow:hover {
// 		background-color: rgba(255, 255, 255, .2);
// 	}

// 	.slide-prev {
// 		left: 30px;
// 		background-image: url("/cn/img/home/part/arrow-to-left.png");
// 	}

// 	.slide-next {
// 		right: 30px;
// 		background-image: url("/cn/img/home/part/arrow-to-right.png");
// 	}

// 	.slide-list {
// 		width: 93%;
// 		height: 4px;
// 		position: absolute;
// 		text-align: center;
// 		top: 370px;
// 		white-space: nowrap;
// 		overflow: hidden;
// 	}

// 	.slide-item {
// 		display: inline-block;
// 		margin: 0 5px;
// 		width: 50px;
// 		height: inherit;
// 		background-color: #fff;
// 		opacity: 0.3;
// 		transition: opacity .3s;
// 		text-indent: -999999px;
// 	}

// 	.slide-item.slide-item-active {
// 		opacity: 1;
// 	}
// }

#game_banner {
    &.tlc-banner-list {
        position: relative;
        height: 150px;
        line-height: 150px;
        top: 0;
        left: 0;
    }

    .tlc-banner-item {
        display: flex;
        width: 500px;
        height: 161px;
        line-height: inherit;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        box-sizing: border-box;
        color: transparent;
        letter-spacing: -9999px;
        overflow: hidden;
    }

    .tlc-banner-item .img-left {
        width: 30%;
    }

    .tlc-banner-item .txt-right h3 {
        font-weight: bold;
    }

    .tlc-banner-item .txt-right {
        align-items: flex-start;
        padding: 15px;
        justify-content: flex-start;
        line-height: 1;
        letter-spacing: 0;
        display: flex;
        flex-direction: column;
        width: 70%;
        color: #000;
    }

    .tlc-banner-item .txt-end {
        font-size: 25px;
        padding: 30px 5px;
        letter-spacing: 1px;
    }

    .tlc-banner-item img {
        height: 100%;
        width: 100%;
        vertical-align: top;
        border-radius: 10px;
    }

    .tlc-carousel-controller {
        position: absolute;
        height: inherit;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
    }

    .slide-arrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 25%;
        outline: none;
        border-width: 0;
        border-radius: 4px;
        cursor: pointer;
        background-color: #ffffff40;
        transition: background-color 0.3s;
    }

    .slide-arrow:hover {
        background-color: #00000080;
    }

    .slide-prev {
        left: -40px;
        background-image: url("/th/img/home/part/arrow-left.svg");
        opacity: 0.8;
    }

    .slide-next {
        right: -40px;
        background-image: url("/th/img/home/part/arrow-right.svg");
        opacity: 0.8;
    }

    .slide-list {
        width: 100%;
        height: 4px;
        position: absolute;
        text-align: center;
        bottom: 20px;
    }

    .slide-item {
        display: inline-block;
        margin: 0 5px;
        width: 50px;
        height: inherit;
        background-color: #fff;
        opacity: 0.3;
        transition: opacity 0.3s;
        text-indent: 99999px;
    }

    .slide-item.slide-item-active {
        opacity: 1;
    }
}

// Home Banner
#t_banner_wrapper {
    &.tlc-banner-list {
        position: relative;
        height: 400px;
        line-height: 400px;
        top: 0;
        left: 0;
    }
    .tlc-banner-item {
        width: 1120px;
        height: 400px;
        line-height: inherit;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        color: transparent;
        overflow: hidden;
        background-color: #ebebeb;
    }
    .tlc-banner-item.pointer {
        cursor: pointer;
    }
    .tlc-banner-item img {
        height: 100%;
        vertical-align: top;
        border-radius: 10px;
    }
    .tlc-carousel-controller {
        position: absolute;
        height: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
    }
    .slide-arrow {
        width: 40px;
        height: 80px;
        position: absolute;
        top: 152px;
        outline: none;
        border-width: 0;
        border-radius: 4px;
        cursor: pointer;
        background-color: #00000040;
        transition: background-color 0.3s;
    }
    .slide-arrow:hover {
        background-color: #00000080;
    }
    .slide-prev {
        left: 60px;
        background-image: url("/th/img/home/part/arrow-to-left.png");
    }
    .slide-next {
        right: 60px;
        background-image: url("/th/img/home/part/arrow-to-right.png");
    }
    .slide-list {
        width: 93%;
        height: 4px;
        position: absolute;
        text-align: center;
        top: 370px;
        white-space: nowrap;
        overflow: hidden;
    }
    .slide-item {
        display: inline-block;
        margin: 0 5px;
        width: 50px;
        height: inherit;
        background-color: #fff;
        opacity: 0.3;
        transition: opacity 0.3s;
        text-indent: -999999px;
    }
    .slide-item.slide-item-active {
        opacity: 1;
    }
}

#t_banner_wrapper.common-distance-wrap.tlc-banner-list.ProductLobbySlider {
    height: 185px !important;
    line-height: 185px !important;
    position: relative;

    .tlc-banner-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 185px;
    }
    
    .BannerBox {
        height: 185px;
        position: relative;
    }

    .tlc-banner-item img[alt] {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    .slide-arrow {
        top: 76px;
    }
    .slide-list {
        top: 160px; // adjust based on your design
    }
}

.Right-Login-Action {
    display: flex;
    align-items: center;
    .BtnList {
        display: flex;
        // width: 185px;
        gap: 8px;
        div {
            cursor: pointer;
        }
    }
}

.App-Qrcode-List {
    display: flex;
    padding-top: 10px;
    .App-Name {
        color: white;
        text-align: center;
    }
}

.promotionBanner {
    .ant-tabs-tab-btn-disabled {
        display: none;
    }
}

.BannerBox {
    span {
        display: block !important;
    }
}

.GameReferImg {
    .GameName {
        display: none !important;
    }
    .openGame:hover::before {
        height: 100%;
    }
}

// 銀行狀態列表
.bank-status-time {
    position: fixed;
    right: -367px;
    z-index: 99;
    transition: all 1s ease;
    &.open {
        transform: translateX(-367px);
        transition: all 1s ease;
    }
    .bank-icon {
        position: absolute;
        top: 20px;
        cursor: pointer;
        left: -80px;
    }
    .bank-list-container {
        width: 367px;
        min-height: 300px;
        max-height: 700px;
        padding: 30px 0px 30px 12px;
        border-radius: 20px 0 0 20px;
        background-color: #ffffff;
        transition: all 2s ease;
        &.boxShadow {
            box-shadow: -2px 4px 12px 0px rgba(0, 0, 0, 0.25);
            transition: all 1s ease;
        }
        .bank-list {
            max-height: 640px;
            overflow: auto;
            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
            }
            &::-webkit-scrollbar-track {
                border-radius: 4px;
            }
            .ant-collapse {
                background-color: #ffffff;
            }
            .ant-collapse-item {
                border-bottom: 0px;
                .ant-collapse-header {
                    color: #222222;
                    .ant-collapse-arrow {
                        transform: translate(0%, -50%) rotate(180deg);
                        svg {
                            transform: rotate(-90deg);
                        }
                        color: #999999;
                    }
                    .bank-name {
                        display: flex;
                        gap: 10px;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                        .name {
                            flex: 1 0;
                        }
                        .status {
                            font-weight: 500;
                            flex: 0 0 46px;
                            &.online {
                                color: #35c95b;
                            }
                            &.offline {
                                color: #bcbec3;
                            }
                        }
                    }
                }
                .ant-collapse-content {
                    color: #222222;
                    .hint {
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
            .ant-collapse-item-active {
                .ant-collapse-header {
                    border-bottom: 1px solid #efeff4;
                }
                .ant-collapse-content {
                    margin-top: 10px;
                }
            }
        }
    }
}