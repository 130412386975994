.custom-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .ant-pagination-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin: 0 4px;
        border: none;
        font-size: 14px;
        border-radius: 6px; // 方形样式
        background-color: transparent;

        a {
            color: #666666; // 数字默认颜色
        }

        &:hover {
            a {
                color: #00a6ff;
            }
        }

        &.ant-pagination-item-active {
            border: 1px solid #00a6ff;
            a {
                color: #00a6ff;
            }
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        img {
            width: 32px;
            height: 32px;
        }

        .ant-pagination-item-link {
            color: #d4d4d8; // 默认箭头颜色
            border: none;
        }

        &:hover {
            border-color: #00a6ff;

            .ant-pagination-item-link {
                color: #00a6ff; // 悬停时箭头颜色
            }
        }

        &.ant-pagination-disabled {
            opacity: 0.5;

            .ant-pagination-item-link {
                color: #999999; // 禁用状态箭头颜色
            }
        }
    }
}
