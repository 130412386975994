.UploadFilesUserCenter {
    .Top {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        //border-bottom: 1px solid #cccccc;
        padding: 10px 0;
        h2 {
            flex: 0 0 150px;
            margin-bottom: 0;
            color: #222222;
        }
        .txt {
            flex: 1 0;
            color: #999999;
        }
        .howtxt {
            color: #00a6ff;
            cursor: pointer;
            line-height: 14px;
            border-bottom: 1px solid #00a6ff;
        }
    }
}
.Uploadfiles {
    border-top: 1px solid #e0e0e0;
    padding: 25px 0;
    .StatusBox {
        width: 500px;
        height: 296px;
        background-color: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin-top: 20px;
        h2 {
            font: normal normal 500 20px/28px "Noto Sans Thai", Arial, san-serif;
            margin-top: 17px;
        }
        Button {
            margin-top: 10px;
            width: 240px;
        }
        .note {
            text-align: center;
        }
        small {
            color: #999999;
            margin-top: 2px;
        }
    }
    .UploadFilesSet {
        .Title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 500px;
            .name {
                color: #222222;
                display: flex;
                align-items: center;
                gap: 10px;
                // justify-content: space-between;
                // width: 85px;
            }
            .eg {
                color: #00a6ff;
                cursor: pointer;
                height: 14px;
                line-height: 14px;
                border-bottom: 1px solid #00a6ff;
            }
        }
        .UploadNote {
            padding-top: 20px;
            color: #999999;
            width: 490px;
            font-size: 12px;
            margin-bottom: 15px;
        }
        .TextLightYellow {
            width: 500px;
            font-size: 14px;
            margin-bottom: 20px;
        }
        .tryTime {
            width: 251px;
            text-align: center;
            color: #999999;
            font-size: 12px;
            margin-bottom: 13px;
        }
        .Submit {
            width: 251px;
            color: #ffffff;
        }
        .Content {
            display: flex;
            // padding-top: 15px;
            justify-content: space-between;
            width: 505px;
            color: #222222;
            .TextLightRed {
                font-size: 12px;
                width: 240px;
                margin: -20px 0 15px 0;
                padding: 13px 12px 12px;
            }
            .SelectFile {
                width: 240px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px dashed #cccccc;
                border-radius: 10px;
                margin: 10px 0 25px 0;
                color: #00a6ff;
                cursor: pointer;
                img {
                    margin-right: 10px;
                }
            }
            .image-item {
                background-color: #f5f5f5;
                border-radius: 8px;
                width: 240px;
                margin: 10px 0 25px 0;
                cursor: pointer;
                padding: 15px 10px;
                .name {
                    word-break: break-all;
                }
            }
        }
    }
}

.upload-lesson-wrap {
    position: relative;
    width: 538px;
    height: 323px;
    // background-position: 50% 100%;
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain;
    &.upload-ls-1 {
        background-image: url(/th/img/upload/1.png);
    }
    &.upload-ls-2 {
        background-image: url(/th/img/upload/2.png);
    }
    &.upload-ls-3 {
        background-image: url(/th/img/upload/3.png);
    }
    &.upload-ls-4 {
        background-image: url(/th/img/upload/4.png);
    }

    &.address-ls-1 {
        background-image: url(/cn/img/withdrawlesson/address_1.jpg);
    }
    &.address-ls-2 {
        background-image: url(/cn/img/withdrawlesson/address_2.jpg);
    }
    h5 {
        font: normal normal bold 14px/24px Microsoft YaHei;
    }
}
.setText {
    text-align: left;
    padding: 40px 0 52px;
    line-height: 24px;
    ol {
        padding: 0;
    }
}

.EgBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    .imageBox {
        display: inline-block;
        position: relative;
        .statusIcon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
        }
    }
}

.UploadTutorials {
    .ant-modal-body {
        padding: 14px 0 20px;
    }
    #upload_wrapper {
        text-align: center;
        .setText {
            padding: 63px 30px 54px;
            color: #222222;
            min-height: 185px;
            h3 {
                font-weight: bold;
            }
            p {
                font: 14px/18px "Noto Sans Thai", Arial, san-serif;
                margin-bottom: 0;
            }
        }
        .slide-arrow {
            width: 32px;
            height: 64px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            outline: none;
            border-width: 0;
            border-radius: 4px;
            cursor: pointer;
            background-color: #00000040;
            transition: background-color 0.3s;
        }
        .slide-arrow:hover {
            background-color: #00000080;
        }
        .slide-prev {
            left: 31px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("/th/img/upload/icon-left.svg");
        }
        .slide-next {
            right: 31px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("/th/img/upload/icon-right.svg");
        }
        .ant-carousel .custom-Carousel .slick-dots li button {
            background: #999999;
            border-radius: 50%;
            height: 4px;
            width: 4px;
        }
        .ant-carousel .custom-Carousel .slick-dots li.slick-active button {
            background: #00a6ff;
            border-radius: 8px;
            height: 4px;
            width: 12px;
        }
    }
}

.upload-confirm {
    .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns {
            margin: 0 16px 30px;
            .ant-btn {
                width: 160px;
                height: 40px;
                &:first-child {
                    color: #00a6ff;
                    &:hover,
                    &:focus {
                        color: #29bbff;
                        border-color: #29bbff;
                    }
                }
            }
            .ant-btn-primary {
                margin-left: 10px;
            }
        }
        .ant-modal-confirm-body .ant-modal-confirm-content {
            padding: 0 16px;
            margin: 25px 0 20px;
        }
    }
}
