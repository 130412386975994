/* 容器样式 */
.custom-checkbox-container {
    display: flex;
    align-items: center;
    user-select: none;
}

.custom-checkbox-container.checked .custom-checkbox {
    border-color: #00a6ff; /* 勾选状态下的边框颜色 */
}

/* 自定义 Checkbox 样式 */
.custom-checkbox {
    width: 20px;
    height: 20px;
    background-color: #ffffff; /* 背景颜色 */
    border: 2px solid #999999; /* 默认边框颜色 */
    border-radius: 4px; /* 圆角矩形 */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.2s ease;
    cursor: pointer;
}

/* 勾选样式 */
.custom-checkbox-check {
    // width: 10px;
    // height: 10px;
    // background-color: #00a6ff; /* 勾选的蓝色 */
    // clip-path: polygon(14% 44%, 0% 65%, 38% 100%, 100% 20%, 82% 0%, 38% 68%);

    width: 14px;
    height: 14px;
    background-image: url("/th/img/icon/miniTick.svg");
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

/* 文本样式 */
.custom-checkbox-label {
    margin-left: 10px;
    font-size: 16px;
    color: #666666;
}
